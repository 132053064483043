import React from 'react';
import {
  Divider,
  Layout,
  message,
  Checkbox,
  Row,
  Col,
  Collapse,
  Breadcrumb,
  Spin,
} from 'antd';
import {
  SendOutlined, SearchOutlined, DashboardOutlined,
} from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { navigate, Link } from '@reach/router';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import DataTable from '../../../../components/shared/DataTable/DataTable';
import { LoadingSelectors, PushNotificationSelectors, UserSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import { ProfileType } from '../../../../app/enum/profileType';
import { formatPhone } from '../../../../app/utils/string';
import { ApiActions } from '../../../../app/redux/actions';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_PUSH_NOTIFICATION_DETAILS } from '../../../../app/redux/actions/pushNotification';
import { ViewType } from '../../../../app/enum/viewType';
import { GetIcon } from '../../../../app/enum/iconsList';
import { ACTION_SAVE_USERS_PAGINATED } from '../../../../app/redux/actions/user';

class PushNotificationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.dataTableRef = React.createRef();
    this.state = {
      viewType: ViewType.EDIT,
      sendToAll: false,
      params: {
        name: '',
        companyId: '',
        specializationId: '',
      },
      form: {
        title: '',
        body: '',
        userIdList: [],
      },
    };
    this.loadingUser = false;
    this.loadingSend = false;
  }

  async componentDidMount() {
    const { getById, id } = this.props;
    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });
    if (id && id !== 'add') {
      try {
        await getById(id);
        const { dataDetails } = this.props;
        this.setState({
          form: {
            title: dataDetails && dataDetails.title,
            body: dataDetails && dataDetails.body,
            userIdList:
              (dataDetails && dataDetails.users.map((users) => users.id))
              || [],
          },
        });
      } catch (error) {
        //
      }
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let { form } = this.state;
    const { sendToAll } = this.state;

    if ((!form.userIdList || form.userIdList.length === 0) && !sendToAll) {
      message.error(I18n.t('pushNotification.messages.errors.invalid_users'));
      return;
    }

    const schema = yup.object().shape({
      body: yup.string().required(I18n.t('pushNotification.messages.errors.invalid_description')),
      title: yup.string().required(I18n.t('pushNotification.messages.errors.invalid_title')),
    });

    schema.validate(form).then(async () => {
      const { create } = this.props;
      form = {
        userIdList: sendToAll ? [] : form.userIdList,
        notification: {
          title: form.title,
          body: form.body,
        },
      };
      try {
        this.loadingSend = true;
        await create(form, () => {
          message.success(I18n.t('shared.messages.success.addSuccess'));
          navigate(I18n.t('routes.panel.pushNotification.items.pushNotification.url'));
        });
      } catch (err) {
        message.error(I18n.t('pushNotification.messages.errors.generic'));
      } finally {
        this.loadingSend = false;
      }
    }).catch((error) => {
      message.error(error.message);
    });
  }

  onInputChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  getUsers = async (params) => {
    params = {
      ...params,
      profileType: ProfileType.USER,
    };
    const { getPaginated } = this.props;

    this.setState({ params: { ...params } });
    try {
      this.loadingUser = true;
      await getPaginated(params);
    } finally {
      this.loadingUser = false;
    }
  }

  cleanAdvancedFilters = () => this.getUsers({ profileType: ProfileType.USER });

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getUsers(params);
  };

  getColumns = (params) => params.map((o) => (
    {
      ...o,
      title: o.title,
      dataIndex: o.key,
      key: o.key,
      render: o.render ? o.render : undefined,
    }
  ))

  onSelectRows = (rowsKeys) => {
    if (this.state.viewType !== ViewType.EDIT) {
      const { form } = this.state;
      form.userIdList = rowsKeys;
      this.setState({ form });
    }
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const { form, params, viewType } = this.state;
    const {
      dataPaginated, loading,
    } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <Content className="panel__layout__content panel__layout__content--breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <DashboardOutlined />  <span>{I18n.t('routes.panel.pageTitle')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={I18n.t('routes.panel.pushNotification.items.pushNotification.url')}>
                    <span>{I18n.t('routes.panel.pushNotification.items.pushNotification.pageTitle')}</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>
                    {
                      I18n.t(
                        viewType === ViewType.ADD
                          ? 'routes.panel.pushNotification.items.pushNotification.pageTitleAdd'
                          : 'routes.panel.pushNotification.items.pushNotification.pageTitleEdit',
                      )
                    }
                  </span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Content>

            <Content className="panel__layout__content panel__layout__content--advanced-filter">
              <Collapse
                className="advanced-filter"
              >
                <Panel
                  header={I18n.t('shared.advancedFilters.header')}
                  key="1"
                >
                  <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                    <Row gutter={16}>
                      <Col span={8}>
                        <AdvancedInput
                          value={params && params.name}
                          onChange={(val) => this.fieldChange('name', val)}
                          placeholder={I18n.t('shared.type')}
                          label={I18n.t('shared.advancedFilters.name')}
                        />
                      </Col>
                      <Col
                        span={8}
                        className="d-flex align-items-center"
                      >
                        <Checkbox onChange={(e) => this.setState({ sendToAll: e.target.checked })}>
                          {I18n.t('routes.panel.pushNotification.items.pushNotification.details.form.sendToAll.label')}
                        </Checkbox>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col
                        span={24}
                        className="advanced-filter__search-button text-right"
                      >
                        <AdvancedButton
                          type="link"
                          text={I18n.t('shared.advancedFilters.clearButtonText')}
                          onClick={() => this.cleanAdvancedFilters()}
                        />
                        <AdvancedButton
                          htmlType="submit"
                          text={I18n.t('shared.advancedFilters.filterButtonText')}
                          icon={<SearchOutlined />}
                        />
                      </Col>
                    </Row>
                  </form>
                </Panel>
              </Collapse>
            </Content>
          </div>
        </div>

        <form
          className="push-notification__form"
          onSubmit={(ev) => this.onSubmit(ev)}
        >
          <div className="row">
            <div className="col-lg-12">

              {!this.state.sendToAll && (
                <Content className="panel__layout__content">
                  <div className="push-notification">
                    <Row>
                      <Col span={12}>
                        <h2>
                          <span className="panel__layout__content__title__value__icon">
                            {GetIcon(I18n.t('routes.panel.admin.items.user.icon'),
                              { fill: '#D10072', width: 24, height: 24 })}
                          </span>
                          {I18n.t('routes.panel.admin.items.user.usersTitle')}
                        </h2>
                      </Col>
                    </Row>

                    <div className="row">
                      <div className="col-12">
                        <DataTable
                          getMethod={(parameters) => this.getUsers({
                            ...parameters,
                            profileType: ProfileType.USER,
                          })}
                          data={dataPaginated}
                          loading={loading > 0 && this.loadingUser}
                          showSizeChanger
                          pageSizeOptions={
                            dataPaginated
                              && dataPaginated.count
                              && dataPaginated.count > 100
                              ? ['10', '50', '100', dataPaginated.count]
                              : ['10', '20', '50', '100']
                          }
                          ref={this.dataTableRef}
                          columns={this.getColumns(
                            [
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.admin.items.user.dataTable.columns.studentName.key'),
                                title: I18n.t('routes.panel.admin.items.user.dataTable.columns.studentName.title'),
                                render: (value) => value || '--',
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.admin.items.user.dataTable.columns.guardianName.key'),
                                title: I18n.t('routes.panel.admin.items.user.dataTable.columns.guardianName.title'),
                                render: (value) => value || '--',
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.admin.items.user.dataTable.columns.birthday.key'),
                                title: I18n.t('routes.panel.admin.items.user.dataTable.columns.birthday.title'),
                                render: (value) => value || '--',
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.admin.items.user.dataTable.columns.cpf.key'),
                                title: I18n.t('routes.panel.admin.items.user.dataTable.columns.cpf.title'),
                                render: (value) => value || '--',
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.admin.items.user.dataTable.columns.rg.key'),
                                title: I18n.t('routes.panel.admin.items.user.dataTable.columns.rg.title'),
                                render: (value) => value || '--',
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.admin.items.user.dataTable.columns.email.key'),
                                title: I18n.t('routes.panel.admin.items.user.dataTable.columns.email.title'),
                                render: (value) => value || '--',
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.admin.items.user.dataTable.columns.cellphone.key'),
                                title: I18n.t('routes.panel.admin.items.user.dataTable.columns.cellphone.title'),
                                render: (value) => (value && formatPhone(value)) || '--',
                              },
                            ],
                          )}
                          bordered={false}
                          pagination={false}
                          size="middle"
                          locale={{
                            emptyText: I18n.t('shared.nothingToShow'),
                          }}
                          selectable
                          selectedRowKeys={form.userIdList}
                          rowSelection={form.userIdList}
                          onSelectRow={(rows) => this.onSelectRows(rows)}
                        />
                      </div>
                    </div>

                  </div>
                </Content>
              )}

              <Content className="panel__layout__content">
                <Spin
                  spinning={loading > 0 && this.loadingSend}
                  tip={I18n.t('shared.loading')}
                >
                  <div className="push-notification">
                    <div className="row">
                      <div className="col-12">
                        <h5 className="push-notification__block-title">
                          {I18n.t('routes.panel.pushNotification.items.pushNotification.details.form.blockTitle')}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <AdvancedInput
                          label={I18n.t(
                            'routes.panel.pushNotification.items.pushNotification.details.form.title.label',
                          )}
                          onChange={(value) => this.onInputChange('title', value)}
                          value={form && form.title}
                          placeholder={I18n.t('shared.type')}
                          disabled={viewType === ViewType.EDIT}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <AdvancedInput
                          label={I18n.t('routes.panel.pushNotification.items.pushNotification.details.form.body.label')}
                          onChange={(value) => this.onInputChange('body', value)}
                          value={form && form.body}
                          isTextarea
                          placeholder={I18n.t('shared.type')}
                          disabled={viewType === ViewType.EDIT}
                        />
                      </div>
                    </div>

                    <Divider />

                    <div className="row">
                      <div
                        className="col-12 text-right"
                      >
                        <AdvancedButton
                          type="link"
                          text={I18n.t('shared.cancel')}
                          href={I18n.t('routes.panel.pushNotification.items.pushNotification.url')}
                        />
                        <Divider
                          className="form-button-divider"
                          type="vertical"
                        />
                        <AdvancedButton
                          htmlType="submit"
                          text={I18n.t('shared.send')}
                          icon={<SendOutlined />}
                          disabled={viewType === ViewType.EDIT}
                        />
                      </div>
                    </div>
                  </div>
                </Spin>
              </Content>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: UserSelectors.getDataPaginated(state),
  dataDetails: PushNotificationSelectors.getDataDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(
    ApiActions.getPaginated(parameters, ENDPOINTS.users, ACTION_SAVE_USERS_PAGINATED),
  ),
  getById: (id) => dispatch(
    ApiActions.getById(id, ENDPOINTS.pushNotification, ACTION_SAVE_PUSH_NOTIFICATION_DETAILS),
  ),
  create: (data, callback) => dispatch(ApiActions.post(data, ENDPOINTS.pushNotificationSend, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushNotificationDetails);
