import React from 'react';
import {
  Layout,
  Popconfirm,
  Row,
  Col,
  message, Collapse, Tooltip, Button, Breadcrumb, Divider,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../../components/shared/DataTable';
import { LoadingSelectors, PushNotificationSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import * as DateUtils from '../../../../app/utils/date';
import { ApiActions } from '../../../../app/redux/actions';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_PUSH_NOTIFICATIONS_PAGINATED } from '../../../../app/redux/actions/pushNotification';
import { GetIcon } from '../../../../app/enum/iconsList';

const { Panel } = Collapse;

class PushNotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        title: '',
      },
    };
    this.dataTableRef = React.createRef();
  }

  getPaginated = (params) => {
    params = {
      ...params,
    };
    const { getPaginated } = this.props;
    this.setState({ params: { ...params } });
    getPaginated(params);
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getPaginated({ ...params, offset: 0 });
  };

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('shared.messages.deleteSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;
    const {
      dataPaginated,
      loading,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined />  <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pushNotification.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.header')}
              key="0"
            >
              <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.title}
                      onChange={(val) => this.fieldChange('title', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.title')}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.getPaginated()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="pushNotifications">

            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.pushNotification.items.pushNotification.icon'),
                      { fill: '#D10072', width: 24, height: 24 },
                    )}
                  </span>
                  {I18n.t('routes.panel.pushNotification.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.pushNotification.addNewButtonText')}
                  href={`${I18n.t('routes.panel.pushNotification.items.pushNotification.url')}/add`}
                  icon={GetIcon(I18n.t('routes.panel.pushNotification.items.pushNotification.icon'))}
                />

              </Col>
            </Row>

            <Divider />

            <DataTable
              getMethod={(parameters) => this.getPaginated(parameters)}
              data={dataPaginated}
              loading={loading > 0}
              showSizeChanger
              pageSizeOptions={
                dataPaginated
                  && dataPaginated.count
                  && dataPaginated.count > 100
                  ? ['10', '50', '100', dataPaginated.count]
                  : ['10', '20', '50', '100']
              }
              params={params}
              ref={this.dataTableRef}
              columns={
                [
                  {
                    ellipsis: true,
                    width: '25%',
                    key: I18n.t('routes.panel.pushNotification.items.pushNotification.dataTable.columns.title.key'),
                    title: I18n.t('routes.panel.pushNotification.items.pushNotification.dataTable.columns.title.title'),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t('routes.panel.pushNotification.items.pushNotification.dataTable.columns.userCount.key'),
                    title: I18n.t(
                      'routes.panel.pushNotification.items.pushNotification.dataTable.columns.userCount.title',
                    ),
                    render: (value) => value || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t('routes.panel.pushNotification.items.pushNotification.dataTable.columns.createdAt.key'),
                    title: I18n.t(
                      'routes.panel.pushNotification.items.pushNotification.dataTable.columns.createdAt.title',
                    ),
                    render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY'),
                  },
                  {
                    key: I18n.t('routes.panel.pushNotification.items.pushNotification.dataTable.columns.actions.key'),
                    title: '',
                    render: (id) => (
                      <div className="dataTable__item--right">
                        <Popconfirm
                          placement="left"
                          title={I18n.t('shared.confirmTitle')}
                          onConfirm={() => this.remove(id)}
                          okText={I18n.t('shared.yes')}
                          cancelText={I18n.t('shared.no')}
                        >
                          <Tooltip
                            title={I18n.t('shared.remove')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<DeleteOutlined />}
                            />
                          </Tooltip>
                        </Popconfirm>
                        <Tooltip
                          title={I18n.t('shared.details')}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => navigate(`${I18n.t(
                              'routes.panel.pushNotification.items.pushNotification.url',
                            )}/${id}`)}
                          />
                        </Tooltip>
                      </div>
                    ),
                  },
                ]
              }
            />
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: PushNotificationSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(
    ApiActions.getPaginated(parameters, ENDPOINTS.pushNotification, ACTION_SAVE_PUSH_NOTIFICATIONS_PAGINATED),
  ),
  remove: (id, callback) => dispatch(ApiActions.remove(id, ENDPOINTS.pushNotification, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  PushNotificationList,
);
