import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  message, Breadcrumb, Collapse, Button, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  SearchOutlined, DashboardOutlined, IdcardOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../../components/shared/DataTable';
import { ApiActions } from '../../../../app/redux/actions';
import { LoadingSelectors, EnrollmentSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { GetIcon } from '../../../../app/enum/iconsList';
import { ACTION_SAVE_ENROLLMENTS_PAGINATED } from '../../../../app/redux/actions/enrollment';
import { GetUnitTypeTitle, UnitTypeArray } from '../../../../app/enum/unitType';
import { GetScholarGradeTypeTitle, ScholarGradeTypeArray } from '../../../../app/enum/scholarGradeType';
import { EnrollmentTypeArray, GetEnrollmentTypeName } from '../../../../app/enum/enrollmentType';
import { EnrollmentStatusType } from '../../../../app/enum/enrollmentStatusType';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';

class EnrollmentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        unitType: null,
        scholarGradeType: null,
        enrollmentType: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  getPaginated(params) {
    params = {
      ...params,
    };
    const { getPaginated } = this.props;
    this.setState({ params: { ...params } });
    getPaginated(params);
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('shared.messages.deleteSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const {
      dataPaginated,
      loading,
    } = this.props;
    const { params } = this.state;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.enrollment.items.enrollment.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.header')}
              key="0"
            >
              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedSelect
                    disableSearch
                    value={params && params.unitType}
                    defaultValue=""
                    options={UnitTypeArray}
                    label={I18n.t('shared.advancedFilters.unitType')}
                    onChange={(val) => this.fieldChange('unitType', val)}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedSelect
                    disableSearch
                    value={params && params.scholarGradeType}
                    defaultValue=""
                    options={ScholarGradeTypeArray}
                    label={I18n.t('shared.advancedFilters.scholarGradeType')}
                    onChange={(val) => this.fieldChange('scholarGradeType', val)}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedSelect
                    disableSearch
                    value={params && params.enrollmentType}
                    defaultValue=""
                    options={EnrollmentTypeArray}
                    label={I18n.t('shared.advancedFilters.enrollmentType')}
                    onChange={(val) => this.fieldChange('enrollmentType', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.getPaginated()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.getPaginated({ ...params, offset: 0 })}
                  />
                </Col>
              </Row>

            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="enrollment">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.enrollment.items.enrollment.icon'),
                      { fill: '#D10072', width: 24, height: 24 },
                    )}
                  </span>
                  {I18n.t('routes.panel.enrollment.items.enrollment.pageTitle')}
                </h2>
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginated(parameters)}
                data={dataPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                pageSizeOptions={
                  dataPaginated
                    && dataPaginated.count
                    && dataPaginated.count > 100
                    ? ['10', '50', '100', dataPaginated.count.toString()] : ['10', '20', '50', '100']
                }
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.guardian.key'),
                      dataIndex: I18n.t(
                        'routes.panel.enrollment.items.enrollment.dataTable.columns.guardian.dataIndex',
                      ),
                      title: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.guardian.title'),
                      render: (value) => (value && value.guardian && value.guardian.name) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.student.key'),
                      dataIndex: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.student.dataIndex'),
                      title: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.student.title'),
                      render: (value) => (value && value.name) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.unitType.key'),
                      title: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.unitType.title'),
                      render: (value) => (value && GetUnitTypeTitle(value)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.scholarGradeType.key'),
                      title: I18n.t(
                        'routes.panel.enrollment.items.enrollment.dataTable.columns.scholarGradeType.title',
                      ),
                      render: (value) => (value && GetScholarGradeTypeTitle(value)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDate(createdAt, 'DD/MM/YYYY'),
                    },
                    {
                      key: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.process.key'),
                      dataIndex: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.process.dataIndex'),
                      title: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.process.title'),
                      render: (value) => (
                        value
                        && value.enrollmentType
                        && GetEnrollmentTypeName(value.enrollmentType)
                      ) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.enrollment.items.enrollment.dataTable.columns.actions.key'),
                      title: '',
                      render: (id, row) => (
                        <div className="dataTable__item--right">
                          <Tooltip
                            title={I18n.t('shared.details')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<IdcardOutlined />}
                              onClick={() => navigate(`${I18n.t('routes.panel.enrollment.items.enrollmentDetail.url')}/${row.student.id}`)}
                            />
                          </Tooltip>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: EnrollmentSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(
    ApiActions.getPaginated(
      {
        ...parameters,
        enrollmentStatus: EnrollmentStatusType.APPROVED,
      },
      ENDPOINTS.enrollment, ACTION_SAVE_ENROLLMENTS_PAGINATED,
    ),
  ),
  remove: (id, callback) => dispatch(ApiActions.remove(id, ENDPOINTS.enrollment, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  EnrollmentList,
);
