import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { navigate } from '@reach/router';
import AuthRequests from '../../api/auth';
import AuthService from '../../services/auth';

import { addLoading, removeLoading } from './loading';

import * as ApiActions from './api';
import * as UserActions from './user';
import * as EnrollmentActions from './enrollment';
import * as DiscountActions from './discount';
import { ProfileType } from '../../enum/profileType';

export const ACTION_AUTH_LOGIN = 'ACTION_AUTH_LOGIN';
export const ACTION_AUTH_LOGOUT = 'ACTION_AUTH_LOGOUT';

export const ACTION_ME = 'ACTION_ME';

export const saveAuthentication = (authData) => ({
  type: ACTION_AUTH_LOGIN,
  auth: authData,
});

export const cleanAuth = () => async (dispatch) => {
  dispatch({
    type: ACTION_AUTH_LOGOUT,
  });
  dispatch(ApiActions.clean(UserActions.ACTION_CLEAN_USER));
  dispatch(ApiActions.clean(EnrollmentActions.ACTION_CLEAN_ENROLLMENT));
  dispatch(ApiActions.clean(DiscountActions.ACTION_CLEAN_DISCOUNT));
  AuthService.reset();
  AuthService.redirectToLogin();
};

export const authenticate = (userData, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const auth = await AuthRequests.auth(userData);
    AuthService.create(auth);
    dispatch({
      type: ACTION_AUTH_LOGIN,
      auth,
    });
    const data = await AuthRequests.me();
    if (data.profileType !== ProfileType.ADMIN) {
      message.error(I18n.t('routes.login.errors.invalid_credentials'));
      AuthService.reset();
      AuthService.redirectToLogin();
      return undefined;
    }
    if (callback && typeof callback === 'function') {
      callback();
    }
    return navigate(I18n.t('routes.panel.admin.items.administrador.url'));
  } catch (err) {
    if (err.message) {
      message.error(I18n.t(`shared.messages.errors.${err.message}`));
    } else {
      message.error(I18n.t('shared.messages.errors.generic'));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const sendRecoveryEmail = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
      sendTo: 'email',
    };
    await AuthRequests.sendRecoveryEmail(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.message) {
      message.error(I18n.t(`shared.messages.errors.${err.message}`));
    } else {
      message.error(I18n.t('shared.messages.errors.generic'));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const passwordRecovery = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await AuthRequests.passwordRecovery(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.message) {
      message.error(I18n.t(`shared.messages.errors.${err.message}`));
    } else {
      message.error(I18n.t('shared.messages.errors.generic'));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const getMe = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await AuthRequests.me();
    dispatch({
      type: ACTION_ME,
      payload: data,
    });
  } catch (err) {
    if (err.status !== 401) {
      message.error(I18n.t(`shared.messages.errors.${err.message}`));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const logout = () => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanAuth());
  dispatch(removeLoading());
};
