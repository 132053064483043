import React from 'react';
import { Translate } from 'react-redux-i18n';
import { GetIcon, IconsList } from './iconsList';

export const EnrollmentPaymentType = {
  SLIP: 1,
  CARD: 2,
  ALL: 3,
};

export const EnrollmentPaymentTypeArray = [
  {
    id: EnrollmentPaymentType.SLIP,
    name: <Translate value="enum.enrollmentPaymentType.splip" />,
    icon: GetIcon(IconsList.BARCODE),
  },
  {
    id: EnrollmentPaymentType.CARD,
    name: <Translate value="enum.enrollmentPaymentType.card" />,
    icon: GetIcon(IconsList.CREDIT_CARD),
  },
  {
    id: EnrollmentPaymentType.ALL,
    name: <Translate value="enum.enrollmentPaymentType.all" />,
    icon: <span>{GetIcon(IconsList.CREDIT_CARD)} {GetIcon(IconsList.BARCODE)}</span>,
  },
];

export const GetEnrollmentPaymentTypeName = (value) => {
  const pendencyType = EnrollmentPaymentTypeArray.find((o) => o.id === value);
  if (pendencyType) {
    return pendencyType.name;
  }
  return value;
};

export const GetEnrollmentPaymentTypeIcon = (value) => {
  const pendencyType = EnrollmentPaymentTypeArray.find((o) => o.id === value);
  if (pendencyType) {
    return pendencyType.icon;
  }
  return GetEnrollmentPaymentTypeName(value);
};
