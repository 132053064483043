import Immutable from 'seamless-immutable';

import * as constAction from '../actions/payment';

const initialState = Immutable({
  dataPaginated: null,
  dataDetails: null,
  dataSelectives: null,
});

export default function payment(
  state = initialState,
  action,
) {
  switch (action.type) {
    case constAction.ACTION_SAVE_PAYMENTS_PAGINATED:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
    case constAction.ACTION_SAVE_PAYMENT_DETAILS:
      state = {
        ...state,
        dataDetails: action.payload,
      };
      return state;
    case constAction.ACTION_SAVE_PAYMENT_SELECTIVES:
      state = {
        ...state,
        dataSelectives: action.payload,
      };
      return state;
    case constAction.ACTION_CLEAN_PAYMENT:
      state = {
        ...initialState,
      };
      return state;
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.payment.dataPaginated;
}

export function getDataDetails(state) {
  return state.payment.dataDetails;
}

export function getDataSelectives(state) {
  return state.payment.dataSelectives;
}
