export const EnrollmentType = {
  ENROLLMENT: 1,
  RE_ENROLLMENT: 2,
};

export const EnrollmentTypeArray = [
  { id: EnrollmentType.ENROLLMENT, name: 'Matrícula' },
  { id: EnrollmentType.RE_ENROLLMENT, name: 'Rematrícula' },
];

export const GetEnrollmentTypeName = (value) => {
  const enrollmentType = EnrollmentTypeArray.find((o) => o.id === value);
  if (enrollmentType) {
    return enrollmentType.name;
  }
  return value;
};
