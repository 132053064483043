import React from 'react';
import {
  Row,
  Col,
  Layout,
  Card,
  message,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import AdvancedInput from '../../components/shared/AdvancedInput';
import AdvancedButton from '../../components/shared/AdvancedButton';
import { AuthActions } from '../../app/redux/actions';
import * as AuthSelectors from '../../app/redux/reducers/auth';
import { LoadingSelectors } from '../../app/redux/reducers';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      window.location = I18n.t('routes.panel.admin.items.administrador.url');
    }
  }

  userAuth(e) {
    e.preventDefault();

    const { authenticate } = this.props;
    const { email, password } = this.state;

    if (!(email && password)) {
      return message.error(I18n.t('shared.messages.errors.fields'));
    }

    authenticate({ email, password }, () => {
      message.success(I18n.t('shared.messages.success.welcome'));
    });
  }

  render() {
    const { Content } = Layout;
    const {
      email,
      password,
    } = this.state;
    const {
      loading,
    } = this.props;
    return (
      <Content className="login__container">
        <Row
          className="h-100 align-items-center"
          type="flex"
        >
          <div className="login__container__background-image login__container__background-image--top-left" />
          <div className="login__container__background-image login__container__background-image--top-right" />
          <div className="login__container__background-image login__container__background-image--bottom-left" />
          <Col
            span={24}
          >
            <div className="login__container__inner">

              <Row className="login__logo">
                <Col span={24}>
                  <img
                    alt="login logo"
                    className="login__logo__img"
                    src="/assets/img/logo_color.svg"
                  />
                </Col>
              </Row>
              <Card>
                <form onSubmit={(e) => this.userAuth(e)}>

                  <Row className="login__input">
                    <Col span={24}>
                      <AdvancedInput
                        label={I18n.t('routes.login.content.email')}
                        placeholder={I18n.t('routes.login.content.emailPlaceholder')}
                        type="email"
                        value={email}
                        onChange={(value) => this.setState({ email: value })}
                        disabled={loading && loading > 0}
                      />
                    </Col>
                  </Row>
                  <Row className="login__input">
                    <Col span={24}>
                      <AdvancedInput
                        label={I18n.t('routes.login.content.password')}
                        placeholder={I18n.t('routes.login.content.passwordPlaceholder')}
                        type="password"
                        value={password}
                        onChange={(value) => this.setState({ password: value })}
                        disabled={loading && loading > 0}
                        isPassword
                      />
                    </Col>
                  </Row>
                  <Row className="login__input login__input__button">
                    <Col
                      className="text-right"
                      span={24}
                    >
                      <AdvancedButton
                        text={I18n.t('routes.login.content.continueButton')}
                        loading={loading && loading > 0}
                        htmlType="submit"
                        long
                      />
                    </Col>
                    <Col
                      span={24}
                      className="text-center mt-3"
                    >
                      <span>{I18n.t('routes.login.content.forgotPassword')}</span>
                      <Link
                        className="login__input__button__back"
                        to={I18n.t('routes.forgotPassword.url')}
                      >
                        {' '} {I18n.t('shared.clickHere')}
                      </Link>
                    </Col>
                  </Row>
                </form>
              </Card>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: AuthSelectors.isAuthenticated(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (user, callback) => dispatch(AuthActions.authenticate(user, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
