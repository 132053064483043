import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Switch,
  InputNumber,
} from 'antd';
import * as yup from 'yup';
import moment from 'moment-timezone';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedDatePicker from '../../../../components/shared/AdvancedDatePicker/AdvancedDatePicker';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';
import { EnrollmentPaymentTypeArray } from '../../../../app/enum/enrollmentPaymenntType';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        enrollmentValue: null,
        maxDate: null,
        paymentType: null,
        installments: new Array(12).fill({ installmentsNum: 0, interest: 0, isActive: false })
          .map((installment, index) => ({ ...installment, installmentsNum: index + 1 })),
      },
    };
  }

  componentDidMount() {
    const { data, id } = this.props;
    if (data && id !== 'add') {
      this.setState((state) => ({
        form: {
          enrollmentValue: data && data.enrollmentValue,
          maxDate: data && data.maxDate,
          paymentType: data && data.paymentType,
          installments: state.form.installments.map((item) => {
            item = data.installments.find(
              (installmentData) => installmentData.installmentsNum === item.installmentsNum,
            ) || item;
            return {
              id: item.id,
              installmentsNum: item.installmentsNum,
              interest: +item.interest,
              isActive: item.isActive,
            };
          }),
        },
      }));
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;
    const scheman = yup.object().shape({
      maxDate: yup.date()
        .nullable({ isNullable: false })
        .required(I18n.t('shared.messages.errors.invalid_max_date')),
      paymentType: yup.number()
        .min(1, I18n.t('shared.messages.errors.invalid_payment_paymentType'))
        .max(3, I18n.t('shared.messages.errors.invalid_payment_paymentType'))
        .nullable({ isNullable: false })
        .required(I18n.t('shared.messages.errors.invalid_payment_paymentType')),
    });

    scheman
      .validate(form)
      .then(() => {
        const { data, submitFunction } = this.props;
        submitFunction(data ? data.id : null, form);
      }).catch((error) => {
        message.error(error.message);
      });
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  interestFieldChange(index, value) {
    const { form } = this.state;
    form.installments = form.installments.map((installment, i) => (
      i !== index
        ? installment
        : { ...installment, interest: value }));
    this.setState({ form });
  }

  isActiveFieldChange(index, value) {
    const { form } = this.state;
    form.installments = form.installments.map((installment, i) => (
      i !== index
        ? installment
        : { ...installment, isActive: value }));
    this.setState({ form });
  }

  render() {
    const {
      loading,
    } = this.props;
    const { form } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form
              name="paymentForm"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    type="number"
                    min={0}
                    label={I18n.t('routes.panel.financial.items.payment.details.form.enrollmentValue.label')}
                    value={form && form.enrollmentValue}
                    onChange={(val) => this.fieldChange('enrollmentValue', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedDatePicker
                    label={I18n.t('routes.panel.financial.items.payment.details.form.maxDate.label')}
                    value={form && form.maxDate && moment(form.maxDate)}
                    onChange={(val) => this.fieldChange('maxDate', val.toDate())}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedSelect
                    disableSearch
                    value={form && form.paymentType}
                    defaultValue=""
                    options={EnrollmentPaymentTypeArray}
                    label={I18n.t('routes.panel.financial.items.payment.details.form.paymentType.label')}
                    onChange={(val) => this.fieldChange('paymentType', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={4}>
                  {I18n.t('routes.panel.financial.items.payment.details.form.installments.label')}
                </Col>
                <Col span={4}>
                  {I18n.t('routes.panel.financial.items.payment.details.form.interest.label')} %
                </Col>
                <Col span={16}>
                  Ativo?
                </Col>
              </Row>

              <Row gutter={[24, 8]}>
                {Array.from(Array(12).keys()).map((item) => (
                  <React.Fragment key={item}>
                    <Col span={4}>
                      {item + 1}
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        min={0}
                        max={1}
                        step={0.01}
                        value={form && form.installments && form.installments[item] && form.installments[item].interest}
                        onChange={(value) => this.interestFieldChange(item, value)}
                        decimalSeparator=","
                      />
                    </Col>
                    <Col span={16}>
                      <Switch
                        checked={form && form.installments && form.installments[item].isActive}
                        defaultChecked={form && form.installments && form.installments[item].isActive}
                        onChange={(value) => this.isActiveFieldChange(item, value)}
                      />
                    </Col>
                  </React.Fragment>
                ))}
              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.cancel')}
                    href={I18n.t('routes.panel.financial.items.payment.url')}
                  />
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.save')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(
  mapStateToProps,
  null,
)(
  PaymentForm,
);
