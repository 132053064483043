import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import thunk from 'redux-thunk';

import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import loadingReducer from './reducers/loading';
import discountReducer from './reducers/discount';
import enrollmentReducer from './reducers/enrollment';
import enrollmentKanbanReducer from './reducers/enrollmentKanban';
import pushNotificationReducer from './reducers/pushNotification';
import studentReducer from './reducers/student';
import paymentReducer from './reducers/payment';

export default () => {
  const store = createStore(
    combineReducers(
      {
        i18n: i18nReducer,
        auth: authReducer,
        loading: loadingReducer,
        user: userReducer,
        discount: discountReducer,
        enrollment: enrollmentReducer,
        enrollmentKanban: enrollmentKanbanReducer,
        pushNotification: pushNotificationReducer,
        student: studentReducer,
        payment: paymentReducer,
      },
    ),
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );

  return store;
};
