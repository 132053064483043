import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  DatePicker,
} from 'antd';

export const dateFormat = 'DD/MM/YYYY';

const AdvancedDatePicker = (
  {
    label,
    onChange,
    value,
  },
) => (
  <div className="advanced-datepicker">
    <label className="advanced-datepicker__label">
      <span className="advanced-datepicker__label__inner">{label}</span>
      <DatePicker
        value={value}
        format={dateFormat}
        onChange={onChange}
        placeholder={I18n.t('shared.selectSomeValue')}
        style={
            { width: '100%' }
          }
      />
    </label>
  </div>
);

export default AdvancedDatePicker;
