import Immutable from 'seamless-immutable';

import * as constAction from '../actions/enrollmentKanban';

const initialState = Immutable({
  dataPaginated: null,
});

export default function enrollmentKanban(
  state = initialState,
  action,
) {
  switch (action.type) {
    case constAction.ACTION_SAVE_ENROLLMENT_KANBAN:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
    case constAction.ACTION_CLEAN_ENROLLMENT_KANBAN:
      state = {
        ...initialState,
      };
      return state;
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.enrollmentKanban.dataPaginated;
}
