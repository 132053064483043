import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import getInstance from './config';

export default class Api {
  static async getPaginated(params, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.get(apiUrl, { params });
    return data;
  }

  static async getById(id, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.get(`${apiUrl}/${id}`);
    return data;
  }

  static async getSelectives(apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.get(`${apiUrl}/selectives`);
    return data;
  }

  static async put(id, newData, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.put(`${apiUrl}/${id}`, newData);
    return data;
  }

  static async post(newData, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.post(apiUrl, newData);
    return data;
  }

  static async remove(id, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.delete(`${apiUrl}/${id}`);
    return data;
  }

  static async getSearch(params, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.get(`${apiUrl}/search`, { params });
    return data;
  }

  static async importFile(file, url, contentType, onUploadProgress, cancelToken) {
    const instance = await getInstance({ contentType }, process.env.REACT_APP_STORAGE_TIMEOUT || 180000);
    try {
      const { data } = await instance.put(url, file, { onUploadProgress, cancelToken });
      return data;
    } catch (error) {
      throw new Error('cancelado');
    }
  }

  static async getUrlToUploadFile(params) {
    try {
      const instance = await getInstance();
      const { data } = await instance.get('/utils/request-upload', { params });
      return data;
    } catch (err) {
      message.error(I18n.t(`routes.panel.contentDetails.messages.fileError.${err.message}`));
    }
  }
}
