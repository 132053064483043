import { Spin } from 'antd';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { I18n } from 'react-redux-i18n';
import { removeSpecialChars } from '../../../app/utils/string';
import DraggableComponent from '../DraggableComponent/DraggableComponent';

const DroppableComponent = (
  {
    column,
    id,
    onCardClick,
    cardIdsLoading,
  },
) => (
  <Droppable
    droppableId={id}
  >
    {(provided, snapshot) => (
      <div
        className="droppable-component"
        {...provided.droppableProps}
        ref={provided.innerRef}
        style={{
          background: snapshot.isDraggingOver ? 'rgba(0, 150, 221, 0.1)' : 'rgb(235, 235, 235)',
          padding: 10,
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={`outer ${removeSpecialChars(column.name.toLowerCase())}`}>
              <div className="inner">&nbsp;</div>
            </div>
            <span style={{ fontSize: 14, fontWeight: 'bold' }}>{column.name}</span>
          </div>
          <span style={{ color: '#bbbbbb', marginLeft: 18 }}>
            {column.items && column.items.length}{' '}
            {I18n.t('routes.panel.enrollment.items.enrollmentKanban.registrationsMade')}
          </span>
        </div>
        {column.items && column.items.map((item, index) => (
          <Spin
            spinning={cardIdsLoading.includes(item.id)}
            key={item.id}
            tip={I18n.t('shared.loading')}
          >
            <DraggableComponent
              onCardClick={onCardClick}
              item={item}
              index={index}
            />
          </Spin>
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export default DroppableComponent;
