import React from 'react';
import { Translate } from 'react-redux-i18n';

export const OrderStatus = {
  PENDING_PAYMENT: 1,
  PAID: 2,
  APPROVED: 3,
  CANCELED: 4,
};

export const OrderStatusArray = [
  { id: OrderStatus.PENDING_PAYMENT, name: <Translate value="enum.orderStatus.pending" />, color: '#cccc00' },
  { id: OrderStatus.PAID, name: <Translate value="enum.orderStatus.paid" />, color: '#99cc99' },
  { id: OrderStatus.APPROVED, name: <Translate value="enum.orderStatus.approved" />, color: '#119911' },
  { id: OrderStatus.CANCELED, name: <Translate value="enum.orderStatus.canceled" />, color: '#991111' },
];

export const GetOrderStatusTitle = (value) => {
  const unit = OrderStatusArray.find((o) => o.id === value);
  if (unit) {
    return unit.name;
  }

  return value;
};

export const GetOrderStatusColor = (value) => {
  const orderStatus = OrderStatusArray.find((o) => o.id === value);
  if (orderStatus) {
    return orderStatus.color;
  }
  return '#bbb';
};
