import React from 'react';
import { Table } from 'antd';
import { I18n } from 'react-redux-i18n';

export const defaultValues = {
  pageSize: 10,
  total: 0,
  offset: 0,
  page: 1,
  data: null,
};

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultValues;
    if (!this.props.notGetOnStart) {
      this.props.getMethod(defaultValues);
    }
  }

  componentDidMount() {
    this.setState(defaultValues);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      let { page, total, pageSize } = this.state;
      if (total !== nextProps.data.count) {
        page = 1;
        pageSize = 10;
      }
      total = nextProps.data.count;
      this.setState({
        total,
        page,
        pageSize,
      });
    }

    this.setState({ data: nextProps.data });
  }

  getColumns = (params) => params.map((o) => (
    {
      ...o,
      title: o.title,
      dataIndex: o.dataIndex || o.key,
      key: o.key,
      render: o.render ? o.render : undefined,
    }
  ))

  getRows = (data) => data.map((o) => (
    {
      ...o,
      key: o.id ? o.id.toString() : Math.random().toString(36),
    }
  ))

  handleTableChange = (pagination) => {
    const { params } = this.props;
    this.setState({
      page: pagination.current,
      pageSize: pagination.pageSize,
    }, () => {
      this.props.getMethod({
        ...params,
        offset: pagination.current > 1 ? (pagination.current - 1) : 0,
        pageSize: pagination.pageSize,
      });
    });
  }

  reset = () => {
    this.setState(defaultValues);
    this.props.getMethod(defaultValues);
  }

  rowSelection() {
    const { onSelectRowDisable } = this.props;
    return {
      onChange: (selectedRowKeys, seletedItems) => {
        const { onSelectRow } = this.props;
        onSelectRow(selectedRowKeys, seletedItems);
      },
      getCheckboxProps: (record) => ({
        disabled: onSelectRowDisable && onSelectRowDisable(record),
      }),
    };
  }

  render() {
    const {
      columns,
      loading,
      selectable,
      selectedRowKeys,
      showSizeChanger,
      pageSizeOptions,
    } = this.props;

    const {
      total,
      page,
      pageSize,
      data,
    } = this.state;

    return (
      <div className="dataTable__wrapper">
        {selectable ? (
          <Table
            rowSelection={{
              type: 'checkbox',
              ...this.rowSelection(),
              selectedRowKeys,
            }}
            dataSource={data && this.getRows(data.rows)}
            columns={this.getColumns(columns)}
            bordered={false}
            loading={loading}
            pagination={{
              current: page,
              total,
              page,
              pageSize,
              showSizeChanger,
              pageSizeOptions,
            }}
            size="middle"
            onChange={(ev) => this.handleTableChange(ev)}
            locale={{
              emptyText: I18n.t('shared.nothingToShow'),
            }}
          />
        ) : (
          <Table
            dataSource={data && this.getRows(data.rows)}
            columns={this.getColumns(columns)}
            bordered={false}
            loading={loading}
            pagination={{
              current: page,
              total,
              page,
              pageSize,
              showSizeChanger,
              pageSizeOptions,
            }}
            size="middle"
            onChange={(ev) => this.handleTableChange(ev)}
            locale={{
              emptyText: I18n.t('shared.nothingToShow'),
            }}
          />
        )}
      </div>
    );
  }
}

export default DataTable;
