import React from 'react';
import {
  Select,
  Empty,
  Avatar,
} from 'antd';
import { I18n } from 'react-redux-i18n';

const { Option } = Select;

function hasValueProp(hasValue, value) {
  let response = null;

  if (hasValue) {
    response = {
      value,
    };
  }

  return response;
}

const AdvancedSelect = (
  {
    value,
    defaultValue,
    options,
    label,
    onChange,
    disabled,
    placeholder,
    disableSearch,
    onSearch,
    hasValue,
    mode,
    labelStyle,
    photoUrl,
    dropdownClassName,
    optionClassName,
    allowClear,
  },
) => (
  <div className="advanced-select">
    <label className="advanced-select__label">
      <span
        style={labelStyle}
        className="advanced-select__label__inner"
      >
        {label}
      </span>
      <Select
        {...hasValueProp(hasValue, value)}
        style={{ width: '100%' }}
        value={value || undefined}
        defaultValue={defaultValue || undefined}
        onChange={onChange}
        optionFilterProp={disableSearch ? 'children' : undefined}
        disabled={disabled || false}
        placeholder={placeholder || I18n.t('shared.selectSomeValue')}
        showSearch={!disableSearch || false}
        allowClear={allowClear !== false}
        mode={mode || undefined}
        dropdownClassName={dropdownClassName || ''}
        onSearch={onSearch ? (val) => onSearch(val) : undefined}
        notFoundContent={
          (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={I18n.t('shared.notFoundSearch')}
            />
          )
        }
        filterOption={disableSearch ? (
          input,
          option,
        ) => (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        ) : undefined}
      >
        {options && options.length > 0 && options.map((a) => (
          <Option
            key={a.id ? a.id.toString() : 'all'}
            value={a.id}
            className={optionClassName || ''}
          >
            {photoUrl
              ? <Avatar src={a.photoUrl} />
              : null}
            {a.name || a.title}
          </Option>
        ))}
      </Select>
    </label>
  </div>
);

export default AdvancedSelect;
