import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Checkbox,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import { ViewType } from '../../../../app/enum/viewType';
import { validateEmail } from '../../../../app/utils/string';
import { ProfileType } from '../../../../app/enum/profileType';

class AdministratorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordVisible: false,
      form: {
        name: null,
        email: null,
        password: null,
        passwordConfirmation: null,
        profileType: ProfileType.ADMIN,
        grantType: 'password',
      },
    };
  }

  componentDidMount() {
    const { data, id } = this.props;
    if (data && id !== 'add') {
      this.setState({
        changePasswordVisible: false,
        form: {
          name: data && data.name,
          email: data && data.email,
          profileType: data && data.profileType,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;
    const { viewType } = this.props;

    if (!validateEmail(form.email)) {
      return message.error(I18n.t('shared.messages.errors.invalid_email'));
    }

    const scheman = yup.object().shape({
      email: yup.string()
        .nullable({ isNullable: false })
        .required(I18n.t('shared.messages.errors.invalid_email')),
      name: yup.string()
        .min(5, I18n.t('shared.messages.errors.invalid_name'))
        .nullable({ isNullable: false })
        .required(I18n.t('shared.messages.errors.invalid_name')),
    });

    scheman
      .validate(form)
      .then(() => {
        if (viewType === ViewType.ADD || this.state.changePasswordVisible) {
          if (!(form.password && form.passwordConfirmation)) {
            return message.error(I18n.t('shared.messages.errors.passwordsRequired'));
          }
          if (form.password !== form.passwordConfirmation) {
            return message.error(I18n.t('shared.messages.errors.passwordsMismatch'));
          }
        }

        const { data, submitFunction } = this.props;

        if (viewType === ViewType.EDIT && !this.state.changePasswordVisible) {
          delete form.password;
          delete form.passwordConfirmation;
        }

        try {
          submitFunction(data ? data.id : null, form);
        } catch (error) {
          console.log(error);
        }
      }).catch((error) => {
        message.error(error.message);
      });
  }

  removeDDI = (cellphoneNumber) => {
    const temp = cellphoneNumber.split('');
    return temp.slice(2).join('');
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const {
      loading,
      viewType,
    } = this.props;
    const { form, changePasswordVisible } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form
              name="administratorForm"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.administrador.details.form.email.label')}
                    value={form && form.email}
                    onChange={(val) => this.fieldChange('email', val)}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.administrador.details.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>
              </Row>

              {viewType === ViewType.EDIT && (
                <Row
                  gutter={16}
                  className="form-password-divider"
                >
                  <Col span={24}>
                    <Checkbox
                      onChange={(e) => this.setState({ changePasswordVisible: e.target.checked })}
                    >
                      {I18n.t('routes.panel.admin.items.administrador.details.changePasswordPhrase')}
                    </Checkbox>
                  </Col>
                </Row>
              )}

              {(viewType === ViewType.ADD || changePasswordVisible) && (
                <Row gutter={16}>
                  <Col span={12}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.admin.items.administrador.details.form.password.label')}
                      value={form && form.password}
                      onChange={(val) => this.fieldChange('password', val)}
                      isPassword
                    />
                  </Col>
                  <Col span={12}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.admin.items.administrador.details.form.passwordConfirmation.label')}
                      value={form && form.passwordConfirmation}
                      onChange={(val) => this.fieldChange('passwordConfirmation', val)}
                      isPassword
                    />
                  </Col>
                </Row>
              )}

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.cancel')}
                    href={I18n.t('routes.panel.admin.items.administrador.url')}
                  />
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.save')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(
  mapStateToProps,
  null,
)(
  AdministratorForm,
);
