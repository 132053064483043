import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Spin,
  Image,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DashboardOutlined,
} from '@ant-design/icons';
import { ApiActions } from '../../../app/redux/actions';
import { LoadingSelectors, EnrollmentSelectors } from '../../../app/redux/reducers';
import * as DateUtils from '../../../app/utils/date';
import { ENDPOINTS } from '../../../app/endpoints/endpoints';
import { GetIcon } from '../../../app/enum/iconsList';
import { ACTION_SAVE_ENROLLMENTS_DETAILS } from '../../../app/redux/actions/enrollment';
import { GetUnitTypeTitle } from '../../../app/enum/unitType';
import { GetScholarGradeTypeTitle } from '../../../app/enum/scholarGradeType';
import {
  formatCPF, formatPhone, formatRG, formatZipCode,
} from '../../../app/utils/string';
import { GetDocumentTypeName } from '../../../app/enum/documentType';
import { GetDocumentStatusName } from '../../../app/enum/documentStatus';
import { GetOrderStatusColor, GetOrderStatusTitle } from '../../../app/enum/order-status';
import { GetEnrollmentStatusColor, GetEnrollmentStatusName } from '../../../app/enum/enrollmentStatusType';

class EnrollmentDetails extends React.PureComponent {
  componentDidMount() {
    const { getById, id } = this.props;

    getById(id);
  }

  render() {
    const { Content } = Layout;
    const {
      dataDetails,
      loading,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="enrollment-detail">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.enrollment.items.enrollmentDetail.icon'),
                      { fill: '#D10072', width: 24, height: 24 },
                    )}
                  </span>
                  {(dataDetails && dataDetails.guardian && dataDetails.guardian.name) || 'Detalhes'}
                </h2>
              </Col>
            </Row>

            <Divider />

            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="enrollment-detail__container">
                <div className="row mx-1">
                  <div className="col-4">
                    <div className="table-header row py-2 mb-1 mr-0">
                      <div className="col-12">
                        <span>
                          <strong>
                            {I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.header')}
                          </strong>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.name')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.name) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.phone')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.phone && formatPhone(dataDetails.guardian.phone)) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.cpf')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.cpf && formatCPF(dataDetails.guardian.cpf)) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.rg')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.rg && formatRG(dataDetails.guardian.rg)) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.email')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.email) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.zipCode')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.address.zipCode && formatZipCode(dataDetails.guardian.address.zipCode)) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.state')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.address.state) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.city')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.address.city) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.street')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.address.street) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.number')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.address.number) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.guardian.neighborhood')}: </strong>
                        {(dataDetails && dataDetails.guardian && dataDetails.guardian.address.neighborhood) || '--'}
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="table-header row py-2 mb-1 mr-0">
                      <div className="col-12">
                        <span>
                          <strong>
                            {I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.header')}
                          </strong>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.name')}: </strong>
                        {(dataDetails && dataDetails.name) || '--'}
                      </div>

                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.birthday')}: </strong>
                        {(dataDetails && dataDetails.birthday && DateUtils.humanizeDate(dataDetails.birthday, 'DD/MM/YYYY')) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.cpf')}: </strong>
                        {(dataDetails && dataDetails.cpf && formatCPF(dataDetails.cpf)) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.rg')}: </strong>
                        {(dataDetails && dataDetails.rg && formatRG(dataDetails.rg)) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.unitType')}: </strong>
                        {(dataDetails && dataDetails.enrollments && dataDetails.enrollments.length > 0 && dataDetails.enrollments[dataDetails.enrollments.length - 1].unitType && GetUnitTypeTitle(dataDetails.enrollments[dataDetails.enrollments.length - 1].unitType)) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.scholarGradeType')}: </strong>
                        {(dataDetails && dataDetails.enrollments && dataDetails.enrollments.length > 0 && dataDetails.enrollments[dataDetails.enrollments.length - 1].scholarGradeType && GetScholarGradeTypeTitle(dataDetails.enrollments[dataDetails.enrollments.length - 1].scholarGradeType)) || '--'}
                      </div>
                      <div className="col-12 my-2">
                        <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.sameAddressAsGuardian')}: </strong>
                        {dataDetails && dataDetails.sameAddressAsGuardian ? 'SIM' : 'NÃO'}
                      </div>

                      {!(dataDetails && dataDetails.sameAddressAsGuardian) && (
                        <>
                          <div className="col-12 my-2">
                            <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.zipCode')}: </strong>
                            {(dataDetails && dataDetails.address.zipCode && formatZipCode(dataDetails.address.zipCode)) || '--'}
                          </div>
                          <div className="col-12 my-2">
                            <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.state')}: </strong>
                            {(dataDetails && dataDetails.address.state) || '--'}
                          </div>
                          <div className="col-12 my-2">
                            <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.city')}: </strong>
                            {(dataDetails && dataDetails.address.city) || '--'}
                          </div>
                          <div className="col-12 my-2">
                            <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.street')}: </strong>
                            {(dataDetails && dataDetails.address.street) || '--'}
                          </div>
                          <div className="col-12 my-2">
                            <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.number')}: </strong>
                            {(dataDetails && dataDetails.address.number) || '--'}
                          </div>
                          <div className="col-12 my-2">
                            <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.student.neighborhood')}: </strong>
                            {(dataDetails && dataDetails.address.neighborhood) || '--'}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="table-header row py-2 mb-1 mr-0">
                      <div className="col-12">
                        <span>
                          <strong>
                            {I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.pendency.header')}
                          </strong>
                        </span>
                      </div>
                    </div>
                    <div
                      className="row mb-3"
                    >
                      <div className="col-12 my-2">
                        {(dataDetails
                          && dataDetails.enrollments
                          && dataDetails.enrollments.length > 0
                          && (
                            <div style={{
                              display: 'inline-block',
                              border: '1px solid',
                              borderRadius: 5,
                              padding: 5,
                              borderColor: GetEnrollmentStatusColor(dataDetails.enrollments[dataDetails.enrollments.length - 1].enrollmentStatus),
                              textAlign: 'center',
                              color: GetEnrollmentStatusColor(dataDetails.enrollments[dataDetails.enrollments.length - 1].enrollmentStatus),
                              textTransform: 'uppercase',
                            }}
                            >
                              {GetEnrollmentStatusName(dataDetails.enrollments[dataDetails.enrollments.length - 1].enrollmentStatus)}
                            </div>
                          )
                        ) || '--'}
                      </div>
                    </div>

                    {dataDetails && dataDetails.orders && dataDetails.orders.length > 0 && (
                      <>
                        <div className="table-header row py-2 mb-1 mr-0">
                          <div className="col-12">
                            <span>
                              <strong>
                                {I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.payment.header')}
                              </strong>
                            </span>
                          </div>
                        </div>
                        {dataDetails.orders.map((order) => (
                          <>
                            <div
                              className="row"
                              key={order.id}
                            >
                              <div className="col-12 my-2">
                                <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.payment.orderNumber')}: </strong>
                                {order.orderNumber || '--'}
                              </div>

                              <div className="col-12 my-2">
                                <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.payment.orderStatus')}: </strong>
                                {(order.orderStatus && (
                                  <span style={{ color: GetOrderStatusColor(order.orderStatus) }}>
                                    {GetOrderStatusTitle(order.orderStatus)}
                                  </span>
                                )) || '--'}
                              </div>
                              <div className="col-12 my-2">
                                <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.payment.totalPrice')}: </strong>
                                {(order.orderPayment && `R$ ${order.orderPayment.totalPrice}`) || '--'}
                              </div>
                              <div className="col-12 my-2">
                                <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.payment.installmentsNum')}: </strong>
                                {(order.orderPayment && order.orderPayment.installmentsNum) || '--'}
                              </div>
                              <div className="col-12 my-2">
                                <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.payment.interestAmount')}: </strong>
                                {(order.orderPayment && order.orderPayment.interestAmount && (`${order.orderPayment.interestAmount * 100} %`)) || '--'}
                              </div>
                              <div className="col-12 my-2">
                                <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.payment.paidAt')}: </strong>
                                {(order.orderPayment && order.orderPayment.paidAt && DateUtils.humanizeDateTime(order.orderPayment.paidAt, 'DD/MM/YYYY HH:mm')) || '--'}
                              </div>

                              {order.orderPayment && order.orderPayment.refunded && (
                                <>
                                  <div className="col-12 my-2">
                                    <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.payment.refunded')}: </strong>
                                    {(order.orderPayment && `R$ ${order.orderPayment.refunded}`) || '--'}
                                  </div>
                                  <div className="col-12 my-2">
                                    <strong>{I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.payment.refundedAt')}: </strong>
                                    {(order.orderPayment && order.orderPayment.refundedAt && DateUtils.humanizeDateTime(order.orderPayment.refundedAt, 'DD/MM/YYYY HH:mm')) || '--'}
                                  </div>
                                </>
                              )}
                            </div>
                            <Divider />
                          </>
                        ))}
                      </>
                    )}

                    {dataDetails && dataDetails.documents && dataDetails.documents.length > 0 && (
                      <>
                        <div className="table-header row py-2 mb-3 mt-5 mr-0">
                          <div className="col-12">
                            <span>
                              <strong>
                                {I18n.t('routes.panel.enrollment.items.enrollmentDetail.table.document.header')}
                              </strong>
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          {dataDetails.documents.map((document) => (
                            <React.Fragment key={document.id}>
                              <div
                                className="col-3 my-2"
                              >
                                <Image
                                  className="img-fluid"
                                  style={{ cursor: 'pointer' }}
                                  src={document.documentUrl}
                                  placeholder={(
                                    <Image
                                      src={`${document.documentUrl}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit`}
                                      className="img-fluid"
                                    />
                                  )}
                                />
                              </div>
                              <div className="col-9 my-2">
                                <div>
                                  <strong> Tipo: {document.documentType && GetDocumentTypeName(document.documentType)}</strong>
                                </div>
                                <div>
                                  Status: {document.documentStatus && GetDocumentStatusName(document.documentStatus)}
                                </div>
                                <div>
                                  Envio: {document.createdAt && DateUtils.humanizeDate(document.createdAt, 'DD/MM/YYYY')}
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>

              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataDetails: EnrollmentSelectors.getDataDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getById: (id) => dispatch(
    ApiActions.getById(id, ENDPOINTS.student, ACTION_SAVE_ENROLLMENTS_DETAILS),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  EnrollmentDetails,
);
