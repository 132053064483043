export function getInitials(name) {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  if (initials && initials.length > 0 && initials.length > 2) {
    initials = initials.substr(0, 2);
  }
  return initials;
}

export function validateEmail(str) {
  // eslint-disable-next-line max-len,no-useless-escape
  const exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return exp.test(str);
}

export function removeSpecialChars(str) {
  return str ? str.replace(/[^A-Za-z0-9]/g, '').replace(/\/s/g, '') : null;
}

export function slugify(str) {
  return str.toString().toLowerCase()
    .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
    .replace(/[èÈéÉêÊëË]+/g, 'e')
    .replace(/[ìÌíÍîÎïÏ]+/g, 'i')
    .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
    .replace(/[ùÙúÚûÛüÜ]+/g, 'u')
    .replace(/[ýÝÿŸ]+/g, 'y')
    .replace(/[ñÑ]+/g, 'n')
    .replace(/[çÇ]+/g, 'c')
    .replace(/[ß]+/g, 'ss')
    .replace(/[Ææ]+/g, 'ae')
    .replace(/[Øøœ]+/g, 'oe')
    .replace(/[%]+/g, 'pct')
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export function isValidHex(color) {
  if (!color || typeof color !== 'string') {
    return false;
  }

  if (color.substring(0, 1) === '#') {
    color = color.substring(1);
  }

  switch (color.length) {
    case 3:
      return /^[0-9A-F]{3}$/i.test(color);
    case 6:
      return /^[0-9A-F]{6}$/i.test(color);
    case 8:
      return /^[0-9A-F]{8}$/i.test(color);
    default:
      return false;
  }
}

export function orderArrayObjectBy(array, parameter, order = null) {
  if (array) {
    array = array.sort((a, b) => {
      if (typeof a[parameter] === 'string') {
        return (a[parameter].toLowerCase() < b[parameter].toLowerCase() ? -1 : 1);
      }
      if (typeof a[parameter] === 'number') {
        if (!order || order === 'ASC') {
          return (a[parameter] < b[parameter] ? -1 : 1);
        }
        if (order && order === 'DESC') {
          return (a[parameter] > b[parameter] ? -1 : 1);
        }
      }
      return 0;
    });
  }
  return array;
}

export function formatPhone(value) {
  if (value && value.length === 14) {
    return `${value.slice(1, 3)} (${value.slice(3, 5)}) ${value.slice(5, 10)}-${value.slice(10)}`;
  }
  if (value && value.length === 13) {
    return `${value.slice(0, 2)} (${value.slice(2, 4)}) ${value.slice(4, 9)}-${value.slice(9)}`;
  }
  if (value && value.length === 12) {
    return `(${value.slice(1, 3)}) ${value.slice(3, 8)}-${value.slice(8)}`;
  }
  if (value && value.length === 11) {
    return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7)}`;
  }
  return value;
}

export function formatZipCode(value) {
  if (value && value.length === 8) {
    return `${value.slice(0, 5)}-${value.slice(5)}`;
  }
  return value;
}

export function formatCPF(value) {
  if (value && value.length === 11) {
    return `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6, 9)}-${value.slice(9)}`;
  }
  return value;
}

export function formatRG(value) {
  if (value && value.length === 9) {
    return `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5, 8)}-${value.slice(8)}`;
  }
  return value;
}
