import Immutable from 'seamless-immutable';

import * as constAction from '../actions/discount';

const initialState = Immutable({
  dataPaginated: null,
  dataDetails: null,
  dataSelectives: null,
});

export default function discount(
  state = initialState,
  action,
) {
  switch (action.type) {
    case constAction.ACTION_SAVE_DISCOUNTS_PAGINATED:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
    case constAction.ACTION_SAVE_DISCOUNT_DETAILS:
      state = {
        ...state,
        dataDetails: action.payload,
      };
      return state;
    case constAction.ACTION_SAVE_DISCOUNT_SELECTIVES:
      state = {
        ...state,
        dataSelectives: action.payload,
      };
      return state;
    case constAction.ACTION_CLEAN_DISCOUNT:
      state = {
        ...initialState,
      };
      return state;
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.discount.dataPaginated;
}

export function getDataDetails(state) {
  return state.discount.dataDetails;
}

export function getDataSelectives(state) {
  return state.discount.dataSelectives;
}
