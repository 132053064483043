import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { I18n } from 'react-redux-i18n';
import { Spin } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import ScholarGradeType, { GetScholarGradeTypeTitle } from '../../../app/enum/scholarGradeType';
import { GetUnitTypeTitle } from '../../../app/enum/unitType';

const DraggableComponent = (
  {
    onCardClick,
    loading,
    cardIdsLoading,
    item,
    index,
  },
) => (
  <Draggable
    key={item.id}
    draggableId={item.id}
    index={index}
  >
    {(providedDraggable, snapshotDraggable) => (
      <div
        className="draggable-component"
        ref={providedDraggable.innerRef}
        {...providedDraggable.dragHandleProps}
        {...providedDraggable.draggableProps}
        style={{
          userSelect: 'none',
          backgroundColor: snapshotDraggable.isDragging ? '#263b4e' : '#fdfdfd',
          color: snapshotDraggable.isDragging ? 'white' : '#424242',
          border: snapshotDraggable.isDragging ? 'none' : '1px solid #cfcfcf',
          ...providedDraggable.draggableProps.style,
        }}
      >
        <Spin
          spinning={loading > 0 && cardIdsLoading.includes(item.id)}
          tip={I18n.t('shared.loading')}
        >
          <div style={{ fontSize: 12, marginTop: '5px' }}>
            <span style={{ overflowWrap: 'break-word' }}>
              {(item.unitType && GetUnitTypeTitle(item.unitType)) || '--'}
            </span>
          </div>
          <div style={{ fontWeight: 'bold' }}>
            {item.student.name}
          </div>
          <div style={{ fontSize: 12, marginTop: '5px' }}>
            <span style={{ overflowWrap: 'break-word' }}>
              {(item.scholarGradeType && item.scholarGradeType >= ScholarGradeType.TENTH ? 'Ensino Médio' : 'Ensino Fundamental') || '--'}
            </span>
          </div>
          <div style={{ fontSize: 12, marginTop: '5px' }}>
            <span style={{ overflowWrap: 'break-word' }}>
              {(item.scholarGradeType && GetScholarGradeTypeTitle(item.scholarGradeType)) || '--'}
            </span>
          </div>
          <div
            style={{ fontSize: 12, marginTop: '5px' }}
            className="d-flex justify-content-between"
          >
            <div>
              <span style={{ fontWeight: 'bold' }}>
                {I18n.t('routes.panel.enrollment.items.enrollmentKanban.dataTable.columns.guardian.title')}:{`${' '}`}
              </span>
              {(item.student && item.student.guardian && item.student.guardian.name) || ' --'}
            </div>
            <div>
              <IdcardOutlined
                onClick={() => onCardClick(item)}
                style={{ fontSize: 20 }}
              />
            </div>
          </div>
        </Spin>
      </div>
    )}
  </Draggable>

);

export default DraggableComponent;
