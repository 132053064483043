import React from 'react';
import {
  Layout,
  Row,
  Col,
  message, Breadcrumb, Collapse, Spin, Slider,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  SearchOutlined, DashboardOutlined,
} from '@ant-design/icons';
import { ApiActions } from '../../../../app/redux/actions';
import { LoadingSelectors, EnrollmentKanbanSelectors, StudentSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import DragDropContextComponent from '../../../../components/panel/DragDropContextComponent/DragDropContextComponent';
import { ACTION_SAVE_ENROLLMENT_KANBAN } from '../../../../app/redux/actions/enrollmentKanban';
import {
  EnrollmentStatusTypeArray,
  EnrollmentStatusType,
} from '../../../../app/enum/enrollmentStatusType';
import KanbanDetailsModal from '../kanbanDetailsModal/KanbanDetailsModal';
import { ACTION_SAVE_STUDENT_DETAILS } from '../../../../app/redux/actions/student';
import { ScholarGradeTypeArray } from '../../../../app/enum/scholarGradeType';
import { UnitTypeArray } from '../../../../app/enum/unitType';
import { EnrollmentTypeArray } from '../../../../app/enum/enrollmentType';

class EnrollmentKanban extends React.Component {
  constructor(props) {
    super(props);
    this.horizontalScroll = React.createRef();
    this.state = {
      selectedItem: null,
      cardIdsLoading: [],
      modalVisible: false,
      startCreatedAt: null,
      endCreatedAt: null,
      params: {
        unitType: null,
        scholarGradeType: null,
        enrollmentStatus: '',
        enrollmentType: '',
      },
      columnsFromBackend:
        {
          [EnrollmentStatusTypeArray[EnrollmentStatusType.VACANCY_ANALYSIS - 1].name]: {
            id: EnrollmentStatusType.VACANCY_ANALYSIS,
            name: EnrollmentStatusTypeArray[EnrollmentStatusType.VACANCY_ANALYSIS - 1].name,
            items: [],
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_PAYMENT - 1].name]: {
            id: EnrollmentStatusType.PENDING_PAYMENT,
            name: EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_PAYMENT - 1].name,
            items: [],
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.PROCESSING_PAYMENT - 1].name]: {
            id: EnrollmentStatusType.PROCESSING_PAYMENT,
            name: EnrollmentStatusTypeArray[EnrollmentStatusType.PROCESSING_PAYMENT - 1].name,
            items: [],
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.FINANCIAL_ANALYSIS - 1].name]: {
            id: EnrollmentStatusType.FINANCIAL_ANALYSIS,
            name: EnrollmentStatusTypeArray[EnrollmentStatusType.FINANCIAL_ANALYSIS - 1].name,
            items: [],
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_CONTRACT - 1].name]: {
            id: EnrollmentStatusType.PENDING_CONTRACT,
            name: EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_CONTRACT - 1].name,
            items: [],
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_DOCUMENTATION - 1].name]: {
            id: EnrollmentStatusType.PENDING_DOCUMENTATION,
            name: EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_DOCUMENTATION - 1].name,
            items: [],
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.PAYMENT_ERROR - 1].name]: {
            id: EnrollmentStatusType.PAYMENT_ERROR,
            name: EnrollmentStatusTypeArray[EnrollmentStatusType.PAYMENT_ERROR - 1].name,
            items: [],
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.APPROVED - 1].name]: {
            id: EnrollmentStatusType.APPROVED,
            name: EnrollmentStatusTypeArray[EnrollmentStatusType.APPROVED - 1].name,
            items: [],
          },
        },
    };
  }

  async componentDidMount() {
    await this.setInicialStates();
  }

  modalToggle = () => {
    this.setState((state) => ({
      modalVisible: !state.modalVisible,
    }));
  };

  showModalDetails = async (item) => {
    const { getStudentById } = this.props;
    await getStudentById(item.student.id);
    this.setState({
      modalVisible: true,
      selectedItem: item,
    });
  };

  handleApproveOrReproveDocuments = async (id, data) => {
    const { updateDocument, getStudentById } = this.props;
    try {
      await updateDocument(id, data);
      await getStudentById(this.props.studentDetails.id);
    } catch (error) {
      //
    }
  };

  setInicialStates = async (params) => {
    this.setState({ params: { ...params } }, async () => {
      await this.props.getPaginated(params);
      this.setState((state) => ({
        columnsFromBackend: {
          ...state.columnsFromBackend,
          [EnrollmentStatusTypeArray[EnrollmentStatusType.VACANCY_ANALYSIS - 1].name]: {
            ...state.columnsFromBackend[EnrollmentStatusTypeArray[EnrollmentStatusType.VACANCY_ANALYSIS - 1].name],
            items: this.props.dataPaginated
              && this.props.dataPaginated
                .filter((item) => item.enrollmentStatus === EnrollmentStatusType.VACANCY_ANALYSIS),
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_PAYMENT - 1].name]: {
            ...state.columnsFromBackend[EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_PAYMENT - 1].name],
            items: this.props.dataPaginated
              && this.props.dataPaginated
                .filter((item) => item.enrollmentStatus === EnrollmentStatusType.PENDING_PAYMENT),
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.PROCESSING_PAYMENT - 1].name]: {
            ...state.columnsFromBackend[EnrollmentStatusTypeArray[EnrollmentStatusType.PROCESSING_PAYMENT - 1].name],
            items: this.props.dataPaginated
              && this.props.dataPaginated
                .filter((item) => item.enrollmentStatus === EnrollmentStatusType.PROCESSING_PAYMENT),
          },

          [EnrollmentStatusTypeArray[EnrollmentStatusType.FINANCIAL_ANALYSIS - 1].name]: {
            ...state.columnsFromBackend[EnrollmentStatusTypeArray[EnrollmentStatusType.FINANCIAL_ANALYSIS - 1].name],
            items: this.props.dataPaginated
              && this.props.dataPaginated
                .filter((item) => item.enrollmentStatus === EnrollmentStatusType.FINANCIAL_ANALYSIS),
          },

          [EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_CONTRACT - 1].name]: {
            ...state.columnsFromBackend[EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_CONTRACT - 1].name],
            items: this.props.dataPaginated
              && this.props.dataPaginated
                .filter((item) => item.enrollmentStatus === EnrollmentStatusType.PENDING_CONTRACT),
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_DOCUMENTATION - 1].name]: {
            ...state.columnsFromBackend[EnrollmentStatusTypeArray[EnrollmentStatusType.PENDING_DOCUMENTATION - 1].name],
            items: this.props.dataPaginated
              && this.props.dataPaginated
                .filter((item) => item.enrollmentStatus === EnrollmentStatusType.PENDING_DOCUMENTATION),
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.PAYMENT_ERROR - 1].name]: {
            ...state.columnsFromBackend[EnrollmentStatusTypeArray[EnrollmentStatusType.PAYMENT_ERROR - 1].name],
            items: this.props.dataPaginated
              && this.props.dataPaginated
                .filter((item) => item.enrollmentStatus === EnrollmentStatusType.PAYMENT_ERROR),
          },
          [EnrollmentStatusTypeArray[EnrollmentStatusType.APPROVED - 1].name]: {
            ...state.columnsFromBackend[EnrollmentStatusTypeArray[EnrollmentStatusType.APPROVED - 1].name],
            items: this.props.dataPaginated
              && this.props.dataPaginated
                .filter((item) => item.enrollmentStatus === EnrollmentStatusType.APPROVED),
          },
        },
      }));
    });
  };

  onDragEnd = (result, columns) => {
    if (!result.destination) return undefined;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      this.setState(() => ({
        columnsFromBackend: {
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        },
      }));
      this.updateEnrollmentStatus(removed.id, { enrollmentStatus: destColumn.id });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      this.setState(() => ({
        columnsFromBackend: {
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        },
      }));
    }
  };

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  };

  handleChangeDate = (value, createdAtRangeName) => {
    const { params } = this.state;
    let { startCreatedAt, endCreatedAt } = this.state;
    if (value) {
      params[createdAtRangeName] = `${value[0].toDate()}:${value[1].toDate()}`;
      startCreatedAt = value[0].toDate();
      endCreatedAt = value[1].toDate();
    } else {
      params[createdAtRangeName] = null;
      startCreatedAt = null;
      endCreatedAt = null;
    }
    this.setState({ params, startCreatedAt, endCreatedAt });
  };

  updateEnrollmentStatus = (id, data) => {
    const { update } = this.props;
    this.setState((state) => ({
      cardIdsLoading: [...state.cardIdsLoading, id],
    }), async () => {
      try {
        await update(id, data, () => {
          message.success(I18n.t('shared.messages.success.successEdit'));
        });
      } catch (e) {
        this.setInicialStates();
      } finally {
        this.setState((state) => ({
          cardIdsLoading: [...state.cardIdsLoading].filter((item) => item !== id),
        }));
      }
    });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.setInicialStates(params);
  };

  cleanAdvancedFilters = () => this.setInicialStates();

  compare(a, b) {
    if (a.order > b.order) return 1;
    if (b.order > a.order) return -1;

    return 0;
  }

  orderStatusType(types) {
    const sorted = [
      ...types,
    ];

    const reSort = sorted.sort(this.compare);

    return reSort.filter((o) => !o.hidden);
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const {
      selectedItem,
      params, columnsFromBackend, cardIdsLoading, modalVisible,
    } = this.state;
    const {
      studentDetails,
      loading,
      update,
    } = this.props;

    return (
      <div className="enrollmentKanban">
        {modalVisible && (
          <KanbanDetailsModal
            visible
            onCancel={this.modalToggle}
            data={selectedItem}
            studentDetails={studentDetails}
            handleDocuments={this.handleApproveOrReproveDocuments}
            handleUpdate={update}
          />
        )}

        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.enrollment.items.enrollmentKanban.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.header')}
              key="1"
            >
              <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                <Row gutter={16}>
                  <Col span={6}>
                    <AdvancedSelect
                      disableSearch
                      value={params && params.unitType}
                      defaultValue=""
                      options={UnitTypeArray}
                      label={I18n.t('shared.advancedFilters.unitType')}
                      onChange={(val) => this.fieldChange('unitType', val)}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      disableSearch
                      value={params && params.scholarGradeType}
                      defaultValue=""
                      options={ScholarGradeTypeArray}
                      label={I18n.t('shared.advancedFilters.scholarGradeType')}
                      onChange={(val) => this.fieldChange('scholarGradeType', val)}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      disableSearch
                      value={params && params.enrollmentStatus}
                      defaultValue=""
                      options={this.orderStatusType(EnrollmentStatusTypeArray)}
                      label={I18n.t('shared.advancedFilters.status')}
                      onChange={(val) => this.fieldChange('enrollmentStatus', val)}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      disableSearch
                      value={params && params.enrollmentType}
                      defaultValue=""
                      options={EnrollmentTypeArray}
                      label={I18n.t('shared.advancedFilters.enrollmentType')}
                      onChange={(val) => this.fieldChange('enrollmentType', val)}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Spin
          spinning={loading > 0 && cardIdsLoading.length === 0}
          tip={I18n.t('shared.loading')}
        >
          <Content
            className="panel__layout__content enrollmentKanban__container scrollContainer"
            ref={this.horizontalScroll}
          >
            <div
              className="enrollmentKanban__container__scroll"
            >
              {!modalVisible && (
                <Slider
                  defaultValue={0}
                  tooltipVisible={false}
                  onChange={(val) => {
                    if (document.querySelector('.scrollContainer')) {
                      document.querySelector('.scrollContainer').scrollLeft = (
                        document.querySelector('.scrollContainer').scrollWidth
                        - document.querySelector('.scrollContainer').clientWidth
                      ) * (val / 100);
                    }
                  }}
                />
              )}
            </div>
            <div
              style={{
                display: 'inline-flex',
                flexGrow: 1,
              }}
            >
              <DragDropContextComponent
                onDragEnd={(result) => this.onDragEnd(result, columnsFromBackend)}
                columnsFromBackend={columnsFromBackend}
                onCardClick={(item) => this.showModalDetails(item)}
                cardIdsLoading={cardIdsLoading}
              />

            </div>
          </Content>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: EnrollmentKanbanSelectors.getDataPaginated(state),
  studentDetails: StudentSelectors.getDataDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(
    ApiActions.getPaginated({
      ...parameters,
      pageSize: Number.MAX_SAFE_INTEGER
    }, ENDPOINTS.enrollmentKanban, ACTION_SAVE_ENROLLMENT_KANBAN),
  ),
  getStudentById: (id) => dispatch(
    ApiActions.getById(id, ENDPOINTS.student, ACTION_SAVE_STUDENT_DETAILS),
  ),
  update: (id, data, callback) => dispatch(
    ApiActions.put(id, data, ENDPOINTS.enrollment, callback),
  ),
  updateDocument: (id, data, callback) => dispatch(
    ApiActions.put(id, data, ENDPOINTS.enrollmentDocument, callback),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnrollmentKanban);
