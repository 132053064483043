import React from 'react';
import moment from 'moment-timezone';
import {
  Col,
  Divider,
  Image,
  Modal, Row, Spin, Tabs,
  message,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { humanizeDate, humanizeDateTime } from '../../../../app/utils/date';
import {
  formatZipCode, formatCPF, formatRG, formatPhone, orderArrayObjectBy,
} from '../../../../app/utils/string';
import { GetScholarGradeTypeTitle } from '../../../../app/enum/scholarGradeType';
import { GetUnitTypeTitle } from '../../../../app/enum/unitType';
import { GetEnrollmentStatusColor, GetEnrollmentStatusName } from '../../../../app/enum/enrollmentStatusType';
import { GetDocumentTypeName } from '../../../../app/enum/documentType';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import { DocumentStatus } from '../../../../app/enum/documentStatus';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import { GetOrderStatusTitle, GetOrderStatusColor } from '../../../../app/enum/order-status';
import appConfig from '../../../../app/config/app.config';
import AdvancedCheckbox from '../../../../components/shared/AdvancedCheckbox';

class KanbanDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lockBankSlip: props.data?.lockBankSlip,
      form: {
        declineReason: '',
        isEditingIndex: -1,
        idDocument: 0,
      },
    };
  }

  async handleDocuments(id, data) {
    const { handleDocuments } = this.props;
    const { form } = this.state;

    if (data.documentStatus === DocumentStatus.DECLINED
      && (!form.declineReason || (form.declineReason && form.declineReason.length < 3))) {
      return message.error('Digite um motivo com pelo menos 3 caracteres.');
    }

    await handleDocuments(id, { declineReason: data.declineReason, documentStatus: data.documentStatus });
    this.setState({
      form: {
        declineReason: '',
        isEditingIndex: -1,
        idDocument: 0,
      },
    });
  }

  async changeBankSlipBlock(lockBankSlip) {
    const { data, handleUpdate } = this.props;
    this.setState({ lockBankSlip });
    const payload = {
      lockBankSlip,
    };
    await handleUpdate(data.id, payload);
  }

  render() {
    const { TabPane } = Tabs;
    const {
      visible,
      onCancel,
      data,
      studentDetails,
      loading,
    } = this.props;

    const { form, lockBankSlip } = this.state;

    return (
      <Modal
        centered
        className="kanban-details-modal"
        bodyStyle={{
          minHeight: '50vh', maxHeight: '70vh', overflowY: 'auto', borderRadius: 10,
        }}
        title={(
          <div className="row">
            <div className="col-12 mb-3">
              <div
                style={{
                  display: 'inline-block',
                  border: '1px solid',
                  borderRadius: 5,
                  padding: 5,
                  borderColor: GetEnrollmentStatusColor(
                    studentDetails
                    && studentDetails.enrollments[studentDetails.enrollments.length - 1].enrollmentStatus,
                  ),
                  textAlign: 'center',
                  color: GetEnrollmentStatusColor(studentDetails && studentDetails.enrollments[studentDetails.enrollments.length - 1].enrollmentStatus),
                  textTransform: 'uppercase',
                }}
              >
                {GetEnrollmentStatusName(studentDetails && studentDetails.enrollments[studentDetails.enrollments.length - 1].enrollmentStatus)}
              </div>
            </div>
            <div className="col-12">
              <h2>{data && data.student && data.student.name}</h2>
            </div>
            <div className="col-12">
              <div>Data da matrícula: {data && data.createdAt && humanizeDateTime(data.createdAt, 'DD/MM/YYYY')}</div>
            </div>
          </div>
        )}
        visible={visible}
        onOk={onCancel}
        onCancel={onCancel}
        closable
        footer={null}
        maskClosable
        width="75vw"
      >
        <Tabs
          defaultActiveKey="1"
          // onChange={callback}
        >
          <TabPane
            tab="Dados do responsável"
            key="1"
          >
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="row mt-2">
                <div className="col-6">
                  <h6 className="font-weight-bold">DADOS PESSOAIS</h6>
                  <div className="mb-2">
                    Nome completo: {(studentDetails && studentDetails.guardian.name) || '--'}
                  </div>
                  <div className="mb-2">
                    Telefone: {(
                    studentDetails
                    && studentDetails.guardian.phone
                    && formatPhone(studentDetails.guardian.phone)
                  ) || '--'}
                  </div>
                  <div className="mb-2">
                    Data de nascimento: {(
                    studentDetails
                    && studentDetails.guardian.birthday
                    && humanizeDate(studentDetails.guardian.birthday, 'DD/MM/YYYY')
                  ) || '--'}
                  </div>
                  <div className="mb-2">
                    CPF: {(
                    studentDetails
                    && studentDetails.guardian.cpf
                    && formatCPF(studentDetails.guardian.cpf)
                  ) || '--'}
                  </div>
                  <div className="mb-2">
                    RG: {(studentDetails && studentDetails.guardian.rg && formatRG(studentDetails.guardian.rg)) || '--'}
                  </div>
                  <div className="mb-2">
                    E-mail: {(studentDetails && studentDetails.guardian.email) || '--'}
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-weight-bold">ENDEREÇO</h6>
                  <div className="mb-2">
                    CEP: {(
                    studentDetails
                    && studentDetails.guardian.address
                    && studentDetails.guardian.address.zipCode
                    && formatZipCode(studentDetails.guardian.address.zipCode)
                  ) || '--'}
                  </div>
                  <div className="mb-2">
                    Estado: {(studentDetails && studentDetails.guardian.address && studentDetails.guardian.address.state) || '--'}
                  </div>
                  <div className="mb-2">
                    Cidade: {(studentDetails && studentDetails.guardian.address && studentDetails.guardian.address.city) || '--'}
                  </div>
                  <div className="mb-2">
                    Endereço: {(studentDetails && studentDetails.guardian.address && studentDetails.guardian.address.street) || '--'}
                  </div>
                  <div className="mb-2">
                    Número: {(studentDetails && studentDetails.guardian.address && studentDetails.guardian.address.number) || '--'}
                  </div>
                  <div className="mb-2">
                    Bairro: {(studentDetails && studentDetails.guardian.address && studentDetails.guardian.address.neighborhood) || '--'}
                  </div>
                  <div className="mb-2">
                    Complemento: {(studentDetails && studentDetails.guardian.address && studentDetails.guardian.address.complement) || '--'}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <AdvancedCheckbox
                    label="Bloquear geração de boletos"
                    value={lockBankSlip}
                    onChange={(val) => this.changeBankSlipBlock(val)}
                  />
                </div>
              </div>
            </Spin>
          </TabPane>
          <TabPane
            tab="Dados do aluno"
            key="2"
          >
            <div className="row mt-2">
              <div className="col-6">
                <h6 className="font-weight-bold">DADOS PESSOAIS</h6>
                <div className="mb-2">
                  Nome do aluno: {(studentDetails && studentDetails.name) || '--'}
                </div>
                <div className="mb-2">
                  Data de nascimento: {(
                  studentDetails
                  && studentDetails.birthday
                  && humanizeDate(studentDetails.birthday, 'DD/MM/YYYY')
                ) || '--'}
                </div>
                <div className="mb-2">
                  CPF: {(studentDetails && studentDetails.cpf && formatCPF(studentDetails.cpf)) || '--'}
                </div>
                <div className="mb-2">
                  RG: {(studentDetails && studentDetails.rg && formatRG(studentDetails.rg)) || '--'}
                </div>
                <div className="mb-2">
                  Mesmo endereço do
                  responsável: {studentDetails && studentDetails.sameAddressAsGuardian ? 'SIM' : 'NÃO'}
                </div>
                <div className="mb-2">
                  Escola anterior: {(studentDetails && studentDetails.previousSchoolName) || '--'}
                </div>
              </div>
              {!(studentDetails && studentDetails.sameAddressAsGuardian) && (
                <div className="col">
                  <h6 className="font-weight-bold">ENDEREÇO</h6>
                  <div className="mb-2">
                    CEP: {(
                    studentDetails
                    && studentDetails.address && studentDetails.address.zipCode
                    && formatZipCode(studentDetails.address.zipCode)
                  ) || '--'}
                  </div>
                  <div className="mb-2">
                    Estado: {(studentDetails && studentDetails.address && studentDetails.address.state) || '--'}
                  </div>
                  <div className="mb-2">
                    Cidade: {(studentDetails && studentDetails.address && studentDetails.address.city) || '--'}
                  </div>
                  <div className="mb-2">
                    Endereço: {(studentDetails && studentDetails.address && studentDetails.address.street) || '--'}
                  </div>
                  <div className="mb-2">
                    Número: {(studentDetails && studentDetails.address && studentDetails.address.number) || '--'}
                  </div>
                  <div className="mb-2">
                    Bairro: {(studentDetails && studentDetails.address && studentDetails.address.neighborhood) || '--'}
                  </div>
                  <div className="mb-2">
                    Complemento: {(studentDetails && studentDetails.address && studentDetails.address.complement) || '--'}
                  </div>
                </div>
              )}
              <div className="col" />
            </div>
          </TabPane>
          <TabPane
            tab="Dados da unidade"
            key="3"
          >
            <div className="row mt-2">
              <div className="col-6">
                <h6 className="font-weight-bold">DADOS UNIDADE</h6>
                <div className="mb-2">
                  Unidade: {(data && data.unitType && GetUnitTypeTitle(data.unitType)) || '--'}
                </div>
                <div className="mb-2">
                  Turma: {(data && data.scholarGradeType && GetScholarGradeTypeTitle(data.scholarGradeType)) || '--'}
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane
            tab="Pagamento"
            key="4"
          >
            {studentDetails && studentDetails.orders && studentDetails.orders.map((order) => (
              <>
                  <div
                    className="row mt-2"
                    key={order.id}
                  >
                    <div
                      className="col-6"
                    >
                      <h6 className="font-weight-bold">DADOS DO PAGAMENTO</h6>
                      <div
                        className="mb-2"
                      >
                        Número do pagamento: {order.orderNumber || '--'}
                      </div>
                      <div
                        className="mb-2"
                        key={order.id}
                      >
                        Status do pagamento:{' '}
                        {
                          <span
                            style={{
                              display: 'inline-block',
                              textAlign: 'center',
                              color: GetOrderStatusColor(order.orderStatus),
                            }}
                          >
                            {(order.orderStatus && GetOrderStatusTitle(order.orderStatus))}
                          </span>
                          || '--'
                        }
                      </div>

                    </div>

                    <div
                      className="col-6"
                      key={order.id}
                    >
                      <h6 className="font-weight-bold">DETALHES</h6>
                      <div
                        className="mb-2"
                      >
                        Valor total: R$ {(order.orderPayment && order.orderPayment.totalPrice) || '--'}
                      </div>
                      <div
                        className="mb-2"
                      >
                        Parcela número: {(order.orderPayment && order.orderPayment.installmentsNum) || '--'}
                      </div>
                      <div
                        className="mb-2"
                      >
                        Juros: {(order.orderPayment && order.orderPayment.interestAmount && `${order.orderPayment.interestAmount * 100} %`) || '--'}
                      </div>
                      <div
                        className="mb-2"
                      >
                        Data do pagamento: {(order.orderPayment && order.orderPayment.paidAt && humanizeDateTime(order.orderPayment.paidAt, 'DD/MM/YYYY HH:mm')) || '--'}
                      </div>
                      {order.orderPayment && order.orderPayment.refunded && (
                        <>
                          <div
                            className="mb-2"
                          >
                            Estorno: R$ {(order.orderPayment && order.orderPayment.refunded) || '--'}
                          </div>
                          <div
                            className="mb-2"
                          >
                            Data do estorno: {(order.orderPayment && order.orderPayment.refundedAt && humanizeDateTime(order.orderPayment.refundedAt, 'DD/MM/YYYY HH:mm')) || '--'}
                          </div>
                        </>
                      )}

                    </div>
                  </div>

                  <Divider />

                </>
            ))}
          </TabPane>
          <TabPane
            tab="Contrato"
            key="5"
          >
            <div className="kanban-details-modal__contracts">
              {studentDetails
              && studentDetails.contracts
              && studentDetails.contracts.length
                ? studentDetails.contracts.map((contract, contractIndex) => (
                  <div
                    key={contractIndex.toString()}
                    className="kanban-details-modal__contracts__single"
                  >
                    <span className="kanban-details-modal__contracts__single__number">{contractIndex + 1}</span>
                    <span className="kanban-details-modal__contracts__single__label">
                      Criado em: {moment(contract.createdAt).format('DD/MM/YYYY HH:mm:SS')}&nbsp; - &nbsp;
                    </span>
                    <a
                      href={`${appConfig.docusignPreviewUrl}${contract.contractEnvelopeId}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Visualizar
                    </a>
                  </div>
                )) : (
                  <div>Essa matrícula ainda não gerou nenhum contrato.</div>
                )}
            </div>
          </TabPane>
          <TabPane
            tab="Documentações"
            key="6"
          >
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              {studentDetails
              && studentDetails.documents
              && studentDetails.documents.length > 0
                ? orderArrayObjectBy(studentDetails.documents, 'documentType').map((document, index) => (
                  <div
                    className="row mb-5"
                    key={document.id}
                  >
                    <div className="col-1">
                      <Image
                        className="img-fluid"
                        src={document.documentUrl}
                        placeholder={(
                          <Image
                            src={
                              `${document.documentUrl}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit`
                            }
                            className="img-fluid"
                          />
                        )}
                      />
                    </div>
                    <div className="col">
                      <div className="row flex-column h-100 justify-content-between">
                        <div className="col">
                          <div>
                            <strong>
                              Tipo: {document.documentType && GetDocumentTypeName(document.documentType)}
                            </strong>
                          </div>
                          <div>
                            Adicionado em: {
                            document.createdAt && humanizeDateTime(document.createdAt, 'DD/MM/YYYY HH:mm')
                          }
                          </div>
                        </div>

                        {document.documentStatus === DocumentStatus.PENDING_APPROVAL && (
                          <div className="col">
                            <AdvancedButton
                              text="Aprovar"
                              type="link"
                              style={{ padding: 0, marginRight: 10 }}
                              onClick={() => this.handleDocuments(
                                document.id,
                                {
                                  documentStatus: DocumentStatus.APPROVED,
                                },
                              )}
                            />
                            <AdvancedButton
                              text="Reprovar"
                              type="link"
                              style={{ padding: 0 }}
                              onClick={() => this.setState((state) => ({
                                form: {
                                  ...state.form,
                                  isEditingIndex: index,
                                  idDocument: document.id,
                                },
                              }))}
                            />
                          </div>
                        )}
                        {form && form.isEditingIndex === index && (
                          <div className="col">
                            <AdvancedInput
                              label="Motivo da reprovação"
                              isTextarea
                              value={form && form.declineReason}
                              onChange={(val) => this.setState((state) => ({
                                form: {
                                  ...state.form,
                                  declineReason: val,
                                },
                              }))}
                            />
                            <Row>
                              <Col
                                span={24}
                                className="text-right"
                              >
                                <AdvancedButton
                                  type="link"
                                  text={I18n.t('shared.cancel')}
                                  onClick={() => this.setState((state) => ({
                                    form: {
                                      ...state.form,
                                      isEditingIndex: -1,
                                    },
                                  }))}
                                />
                                <Divider
                                  className="form-button-divider"
                                  type="vertical"
                                />
                                <AdvancedButton
                                  onClick={() => this.handleDocuments(
                                    document.id,
                                    {
                                      declineReason: form.declineReason,
                                      documentStatus: DocumentStatus.DECLINED,
                                    },
                                  )}
                                  text={I18n.t('shared.save')}
                                  icon={<SaveOutlined />}
                                />
                              </Col>
                            </Row>
                          </div>
                        )}
                        {document.documentStatus === DocumentStatus.APPROVED && (
                          <div className="col">
                            <span style={{ color: 'green' }}>
                              Aprovado
                            </span>
                          </div>
                        )}
                        {document.documentStatus === DocumentStatus.DECLINED && (
                          <div className="col">
                            <div style={{ color: 'red' }}>
                              Reprovado
                            </div>

                            <div>
                              Motivo: {document.declineReason}
                            </div>

                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )) : (
                  <span>Nenhum documento enviado</span>
                )}
            </Spin>
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(
  mapStateToProps,
  null,
)(KanbanDetailsModal);
