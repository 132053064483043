export default {
  header: {
    basic: 'NzI0NjE0ZjBjODU4ZmQ1ZDNmNDYwZjYxZTRlNWE3YzM6ZTBjMmU4YjE4MmRiZjQ5Nzg1ZDY4MjU1Zjc4YWQzOTY2Nzg1OTM1MzFlMGI4Y2Y0YThhZTllZDg3YTc2NTM2OA==', // eslint-disable-line
    platform: 'web',
    version: '1.0.0',
  },
  timezone: 'America/Sao_Paulo',
  language: 'pt-br',
  // docusignPreviewUrl : 'https://appdemo.docusign.com/documents/details/',
  docusignPreviewUrl : 'https://app.docusign.com/documents/details/',
};
