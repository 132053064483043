import React from 'react';
import { Translate } from 'react-redux-i18n';

const ScholarGradeType = {
  SIXTH: 1,
  SEVENTH: 2,
  EIGHTH: 3,
  NINTH: 4,
  TENTH: 5,
  ELEVENTH: 6,
};

export const ScholarGradeTypeArray = [
  { id: ScholarGradeType.SIXTH, name: <Translate value="enum.scholarGradeType.sixth" /> },
  { id: ScholarGradeType.SEVENTH, name: <Translate value="enum.scholarGradeType.seventh" /> },
  { id: ScholarGradeType.EIGHTH, name: <Translate value="enum.scholarGradeType.eighth" /> },
  { id: ScholarGradeType.NINTH, name: <Translate value="enum.scholarGradeType.ninth" /> },
  { id: ScholarGradeType.TENTH, name: <Translate value="enum.scholarGradeType.tenth" /> },
  { id: ScholarGradeType.ELEVENTH, name: <Translate value="enum.scholarGradeType.eleventh" /> },
];

export const GetScholarGradeTypeTitle = (value) => {
  const scolarGrade = ScholarGradeTypeArray.find((o) => o.id === value);
  if (scolarGrade) {
    return scolarGrade.name;
  }
  return value;
};

export default ScholarGradeType;
