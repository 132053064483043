import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import DroppableComponent from '../DroppableComponent/DroppableComponent';

const DragDropContextComponent = (
  {
    onDragEnd,
    columnsFromBackend,
    onCardClick,
    cardIdsLoading,
  },
) => (
  <DragDropContext onDragEnd={onDragEnd}>
    {columnsFromBackend && Object.entries(columnsFromBackend).map(([id, column]) => (
      <div
        key={id}
        style={{
          minWidth: '300px',
          marginRight: '5px',
          marginLeft: '5px',
        }}
      >
        <DroppableComponent
          id={id}
          column={column}
          onCardClick={onCardClick}
          cardIdsLoading={cardIdsLoading}
        />
      </div>
    ))}
  </DragDropContext>
);

export default DragDropContextComponent;
