import React from 'react';
import { Translate } from 'react-redux-i18n';

export const UnitType = {
  PENHA: 1,
  VILA_DAS_MERCES: 2,
  VILA_MASCOTE: 3,
  JARDIM_MARAJOARA: 4,
};

export const UnitTypeArray = [
  { id: UnitType.PENHA, name: <Translate value="enum.unitType.penha" /> },
  { id: UnitType.VILA_DAS_MERCES, name: <Translate value="enum.unitType.vilaDasMerces" /> },
  { id: UnitType.VILA_MASCOTE, name: <Translate value="enum.unitType.vilaMascote" /> },
  { id: UnitType.JARDIM_MARAJOARA, name: <Translate value="enum.unitType.jardinMarajoara" /> },
];

export const GetUnitTypeTitle = (value) => {
  const unit = UnitTypeArray.find((o) => o.id === value);
  if (unit) {
    return unit.name;
  }

  return value;
};
