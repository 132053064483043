export const DocumentStatus = {
  PENDING_APPROVAL: 1,
  APPROVED: 2,
  DECLINED: 3,
};

export const DocumentStatusArray = [
  { id: DocumentStatus.PENDING_APPROVAL, name: 'Em aprovação' },
  { id: DocumentStatus.APPROVED, name: 'Aprovado' },
  { id: DocumentStatus.DECLINED, name: 'Reprovado' },
];

export const GetDocumentStatusName = (value) => {
  const enrollmentType = DocumentStatusArray.find((o) => o.id === value);
  if (enrollmentType) {
    return enrollmentType.name;
  }
  return value;
};
