import { Redirect, Router } from '@reach/router';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Panel from './Panel';
import Public from './Public';
import Login from '../../containers/login';
import AdministratorsList from '../../containers/panel/administrators/list';
import AdministratorDetails from '../../containers/panel/administrators/details';
import ForgotPassword from '../../containers/forgotPassword';
import ForgotPasswordCode from '../../containers/forgotPasswordCode';
import DiscountList from '../../containers/panel/discounts/list';
import DiscountDetails from '../../containers/panel/discounts/details';
import PaymentList from '../../containers/panel/payment/list';
import PaymentDetails from '../../containers/panel/payment/details';
import EnrollmentList from '../../containers/panel/enrollment/list';
import EnrollmentPendencyList from '../../containers/panel/enrollmentPendencies/list';
import EnrollmentKanban from '../../containers/panel/enrollmentKanban/kanban';
import PushNotificationList from '../../containers/panel/pushNotifications/list';
import PushNotificationDetails from '../../containers/panel/pushNotifications/details';
import EnrollmentDetails from '../../containers/panel/enrollmentDetails/EnrollmentDetails';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  toggle = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed,
    });
  };

  render() {
    return (
      <Router>
        <Public
          path={I18n.t('routes.login.url')}
          container={<Login />}
          title={`${I18n.t('routes.login.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Public
          path={I18n.t('routes.forgotPassword.url')}
          container={<ForgotPassword />}
          title={`${I18n.t('routes.forgotPassword.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Public
          path={I18n.t('routes.forgotPasswordCode.url')}
          container={<ForgotPasswordCode />}
          title={`${I18n.t('routes.forgotPasswordCode.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.admin.items.administrador.url')}
          container={<AdministratorsList />}
          title={`${I18n.t('routes.panel.admin.items.administrador.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.administrador.url')}/:id`}
          container={<AdministratorDetails />}
          title={`${I18n.t('routes.panel.admin.items.administrador.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.financial.items.discount.url')}
          container={<DiscountList />}
          title={`${I18n.t('routes.panel.financial.items.discount.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.financial.items.discount.url')}/:id`}
          container={<DiscountDetails />}
          title={`${I18n.t('routes.panel.financial.items.discount.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.financial.items.payment.url')}
          container={<PaymentList />}
          title={`${I18n.t('routes.panel.financial.items.payment.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.financial.items.payment.url')}/:id`}
          container={<PaymentDetails />}
          title={`${I18n.t('routes.panel.financial.items.payment.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.enrollment.items.enrollment.url')}
          container={<EnrollmentList />}
          title={`${I18n.t('routes.panel.enrollment.items.enrollment.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.enrollment.items.enrollmentPendency.url')}
          container={<EnrollmentPendencyList />}
          title={
            `${I18n.t('routes.panel.enrollment.items.enrollmentPendency.pageTitle')} - ${I18n.t('application.name')}`
          }
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.enrollment.items.enrollmentKanban.url')}
          container={<EnrollmentKanban />}
          title={
            `${I18n.t('routes.panel.enrollment.items.enrollmentKanban.pageTitle')} - ${I18n.t('application.name')}`
          }
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.enrollment.items.enrollmentDetail.url')}/:id`}
          container={<EnrollmentDetails />}
          title={
            `${I18n.t('routes.panel.enrollment.items.enrollmentDetail.pageTitle')} - ${I18n.t('application.name')}`
          }
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.pushNotification.items.pushNotification.url')}
          container={<PushNotificationList />}
          title={`${I18n.t(
            'routes.panel.pushNotification.items.pushNotification.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.pushNotification.items.pushNotification.url')}/:id`}
          container={<PushNotificationDetails />}
          title={`${I18n.t(
            'routes.panel.pushNotification.items.pushNotification.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />

        {/* <Redirect
          from={I18n.t('routes.panel.url')}
          to={I18n.t('routes.login.url')}
        /> */}
      </Router>
    );
  }
}

export default Content;
