import Url from '../config/api.config';

function getBaseUrl() {
  const { hostname } = window.location;
  let apiUrl = null;
  if (process.env.REACT_APP_API_URL) {
    apiUrl = process.env.REACT_APP_API_URL;
  } else {
    switch (hostname) {
      case 'localhost':
      case 'www.localhost':
        apiUrl = Url.localhost;
        break;
      case 'painel-homolog.escolamais.com':
        apiUrl = Url.development;
        break;
      case 'painel.escolamais.com':
        apiUrl = Url.production;
        break;
      case 'escolamais-dev.mblabs.site':
        apiUrl = Url.developmentLocal;
        break;
      case 'em.com.br':
        apiUrl = Url.productionLocal;
        break;
      default:
        apiUrl = Url.localhost;
    }
  }

  return apiUrl;
}

export function handleAxiosError(err) {
  if (err.response && err.response.status === 400 && err.response.data && err.response.data.error) {
    throw new Error(err.response.data.error);
  }
  if (err.response && err.response.status === 401) {
    // throw new Error(CodeErrors.INVALID_TOKEN);
  }
  if (err.response && err.response.status === 404) {
    throw new Error(err.response.data.error);
  }
  if (err.response && err.response.status === 500) {
    throw new Error();
  }
  const error = {
    url: err.config && err.config.url,
    method: err.config && err.config.method,
    headers: err.config && err.config.headers,
    data: err.config && err.config.data,
    response: null,
    status: null,
    message: err.message,
  };
  if (err.response) {
    error.response = err.response.data;
    error.status = err.response.status;
  }
  return error;
}

export default {
  getBaseUrl,
};
