import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import Api from '../../api/api';

export const clean = (actionType) => ({
  type: actionType,
});

export const getPaginated = (parameters, apiUrl, actionType) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const data = await Api.getPaginated(parameters, apiUrl);
    dispatch({
      type: actionType,
      payload: data,
    });
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const getById = (id, apiUrl, actionType) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = null;
    if (id) {
      data = await Api.getById(id, apiUrl);
    }
    dispatch({
      type: actionType,
      payload: data,
    });
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getSelectives = (apiUrl, actionType) => async (dispatch) => {
  try {
    const data = await Api.getSelectives(apiUrl);
    dispatch({
      type: actionType,
      payload: data,
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const put = (id, data, apiUrl, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.put(id, data, apiUrl);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
    throw new Error(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const post = (data, apiUrl, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.post(data, apiUrl);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
    throw new Error();
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, apiUrl, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.remove(id, apiUrl);
    callback();
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
    throw new Error();
  } finally {
    dispatch(removeLoading());
  }
};
