export default {
  application: {
    name: 'Escola Mais - Dashboard',
    shortDescription: 'Escola Mais',
    title: 'Escola Mais',
    version: {
      title: 'Versão',
      number: '1.0.0',
    },
    footer: {
      poweredBy: 'Powered by',
      poweredByBrand: 'mb.labs',
      poweredByBrandUrl: 'https://mblabs.com.br',
    },
  },
  shared: {
    notFoundSearch: 'Nada encontrado.',
    selectSomeValue: 'Selecione...',
    typeToContinue: 'Digite algo para pesquisar...',
    clickHere: 'clique aqui',
    type: 'Digite...',
    ok: 'OK',
    save: 'Salvar',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    remove: 'Remover',
    details: 'Detalhes',
    file: 'Arquivo',
    send: 'Enviar',
    useValues: 'Utilizar selecionados',
    yes: 'Sim',
    no: 'Não',
    loading: 'Carregando...',
    confirmTitle: 'Você tem certeza?',
    search: 'Pesquisar',
    logout: {
      menuText: 'Sair do sistema',
      confirmation: 'Você tem certeza que deseja sair?',
      confirmButton: 'Tenho certeza, sair.',
    },
    datatable: {
      showing: 'Exibindo',
      showingOf: 'de',
      showingResultsTotal: 'resultados',
    },
    nothingToShow: 'Nada para exibir, verifique os parâmetros e tente novamente.',
    hours: 'horas',
    minutes: 'minutos',
    seconds: 'segundos',
    distanceUnit: 'metros',
    distanceUnitSmall: 'm',
    cantAccess: 'Você não tem permissão para visualizar esta página.',
    searchSomething: 'Digite algo para pesquisar...',
    dateMask: 'DD/MM/YYYY',
    timeMask: 'HH:mm',
    advancedFilters: {
      header: 'Filtros avançados',
      title: 'Título',
      rangeLabel: 'Período',
      taxIdentifier: 'CPF',
      email: 'E-mail',
      name: 'Nome',
      filterButtonText: 'Filtrar',
      exportButtonText: 'Exportar',
      clearButtonText: 'Limpar filtros',
      rangeStart: 'Data inicial',
      rangeEnd: 'Data final',
      code: 'Código',
      place: 'Unidade',
      createdAtRange: 'Data de cadastro',
      studantName: 'Nome do aluno',
      status: 'Status',
      scholarGradeType: 'Ano',
      unitType: 'Unidade',
      value: 'Valor',
      paymentType: 'Tipo de pagamento',
      enrollmentType: 'Tipo matrícula',
    },
    messages: {
      success: {
        addSuccess: 'Adicionado com sucesso.',
        deleteSuccess: 'Removido com sucesso.',
        emailSent: 'E-mail de recuperação enviado (verifique também a caixa de spam).',
        passwordChanged: 'Senha alterada, entre com suas novas credenciais',
        successEdit: 'Atualizado com sucesso.',
        validCode: 'Código válido, prossiga',
        welcome: 'Bem vindo(a) novamente.',
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        invalid_credentials: 'Credenciais inválidas, verifique e tente novamente.',
        bad_credentials: 'Credenciais inválidas, verifique e tente novamente.',
        invalid_token: 'Token inválido ou expirado. Entre novamente.',
        no_access: 'Você não tem acesso a essa tela. Entre em contato com um administrador.',
        user_not_found: 'Usuário não encontrado',
        passwordMismatch: 'As senhas não conferem',
        passwordNotSecure: 'Senha deve ter pelo menos 6 carateres',
        invalid_send_to: 'Parâmetros inválidos',
        generic: 'Algo deu errado. Tente novamente mais tarde.',
        invalid_cpf: 'CPF inválido',
        invalid_name: 'O nome deve ter ao menos 5 caracteres.',
        invalid_email: 'E-mail inválido, verifique e tente novamente.',
        invalid_password_length: 'A senha deve ter ao menos 6 caracteres.',
        invalid_cellphone_length: 'Telefone deve ter 10 ou 11 dígitos, verifique e tente novamente.',
        passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
        passwordsRequired: 'A senha e confirmação de senha são requeridas.',
        invalid_cellphone: 'Telefone inválido, verifique e tente novamente.',
        cellphone_already_used: 'Já existe outro usuário com esse telefone.',
        already_used_cellphone: 'Já existe outro usuário com esse telefone.',
        email_already_registered: 'Já existe outro usuário com esse e-mail.',
        email_already_used: 'Já existe outro usuário com esse e-mail.',
        invalid_password: 'A senha deve ter ao menos 6 caracteres.',
        invalid_date: 'Data inválida.',
        invalid_max_date: 'Data inválida.',
        invalid_payment_type: 'Tipo de pagamento inválido',
        invalid_enrollment_value: 'Valor de pagamento inválido',
        user_invalid_token: 'Sessão expirada. Por favor, faça o login novamente',
      },
    },
  },

  routes: {
    login: {
      url: '/',
      pageTitle: 'Entrar',
      content: {
        header: 'Entre com o e-mail e senha cadastrados',
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail cadastrado',
        password: 'Senha',
        passwordPlaceholder: 'Sua senha segura',
        company: 'Empresa',
        companyAll: 'Todas',
        continueButton: 'Entrar no sistema',
        forgotPassword: 'Esqueci minha senha',
        subHeader: 'Entre com seu e-mail e senha cadastrados',
      },
    },

    forgotPassword: {
      url: '/esqueci-minha-senha',
      pageTitle: 'Esqueci minha senha',
      content: {
        header: 'Entre com o e-mail cadastrado',
        subHeader: 'Iremos enviar os próximos passos no e-mail abaixo para prosseguir com a recuperação de sua senha',
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail cadastrado',
        continueButton: 'Enviar e-mail',
        forgotPassword: 'Esqueci minha senha',
        backToLogin: 'Voltar ao login',
      },
    },

    forgotPasswordCode: {
      url: '/esqueci-minha-senha/definir',
      pageTitle: 'Definir nova senha',
      content: {
        header: 'Entre com o código recebido por e-mail',
        subHeader: 'Insira abaixo o código recebido por e-mail',
        email: 'E-mail',
        emailPlaceholder: 'E-mail',
        code: 'Código recebido por e-mail',
        codePlaceholder: 'Código recebido',
        continueButton: 'Alterar senha',
        notReceivedEmail: 'Não recebi o e-mail',
        passwordChanged: 'Senha alterada com sucesso.',
        password: 'Nova senha',
        passwordPlaceholder: 'Senha segura',
        passwordConfirm: 'Confirme a senha acima',
        passwordConfirmPlaceHolder: 'Confirmação',
        subHeaderPassword: 'Insira uma nova senha no campo abaixo, e também a sua confirmação',
        cancelButton: 'Cancelar',
      },
    },

    panel: {
      pageTitle: 'Painel',

      admin: {
        url: '/painel/administrativo',
        sidebarVisible: 'y',
        sidebarTitle: 'Cadastro',
        icon: 'registration',
        items: {
          administrador: {
            url: '/painel/administrativo/administrador',
            sidebarTitle: 'Administradores',
            pageTitle: 'Gerenciar administradores',
            icon: 'registration',
            addNewButtonText: 'Adicionar administrador',
            pageTitleAdd: 'Adicionar administrador',
            pageTitleEdit: 'Editar administrador',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um administrador',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um administrador',
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do administrador',
              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
          user: {
            sidebarVisible: 'n',
            url: '/painel/administrativo/usuário',
            sidebarTitle: 'Usuários',
            pageTitle: 'Gerenciar usuários',
            usersTitle: 'Usuários',
            icon: 'user',
            addNewButtonText: 'Adicionar usuário',
            pageTitleAdd: 'Adicionar usuário',
            pageTitleEdit: 'Editar usuário',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um usuário',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um usuário',
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do usuário',
              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
              },
            },
            dataTable: {
              columns: {
                studentName: {
                  key: 'studentName',
                  title: 'Aluno',
                },
                guardianName: {
                  key: 'guardianName',
                  title: 'Responsável',
                },
                cellphone: {
                  key: 'cellphone',
                  title: 'Telefone',
                },
                birthday: {
                  key: 'birthday',
                  title: 'Data de nascimento',
                },
                cpf: {
                  key: 'cpf',
                  title: 'CPF',
                },
                rg: {
                  key: 'rg',
                  title: 'RG',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
        },
      },
      enrollment: {
        url: '/painel/matriculas',
        sidebarVisible: 'y',
        sidebarTitle: 'Matrículas',
        icon: 'enrollment',
        items: {
          enrollmentKanban: {
            url: '/painel/matriculas/todas',
            sidebarTitle: 'Gestão das matrículas',
            pageTitle: 'Gerenciar matrículas',
            icon: 'enrollment',
            registrationsMade: 'pedidos em processo',
            dataTable: {
              columns: {
                guardian: {
                  key: 'guardian',
                  title: 'Responsável',
                },
                student: {
                  key: 'student',
                  title: 'Nome do aluno',
                },
                className: {
                  key: 'className',
                  title: 'Turma',
                },
                place: {
                  key: 'place',
                  title: 'Unidade',
                },
                type: {
                  key: 'type',
                  title: 'Tipo',
                },
                pendencyType: {
                  key: 'pendencyType',
                  title: 'Pendências',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
          enrollmentPendency: {
            url: '/painel/matriculas/pendentes',
            sidebarTitle: 'Matrículas pendentes',
            pageTitle: 'Matrículas pendentes',
            icon: 'enrollment',
            dataTable: {
              columns: {
                guardian: {
                  key: 'student.guardian.name',
                  dataIndex: 'student',
                  title: 'Nome do responsável',
                },
                student: {
                  key: 'student.name',
                  dataIndex: 'student',
                  title: 'Nome do aluno',
                },
                scholarGradeType: {
                  key: 'scholarGradeType',
                  title: 'Turma',
                },
                unitType: {
                  key: 'unitType',
                  title: 'Unidade',
                },
                value: {
                  key: 'value',
                  title: 'Valor',
                },
                quote: {
                  key: 'quote',
                  title: 'Parcelas',
                },
                type: {
                  key: 'type',
                  title: 'Tipo',
                },
                enrollmentStatus: {
                  key: 'enrollmentStatus',
                  title: 'Status',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
          enrollment: {
            url: '/painel/matriculas/aprovadas',
            sidebarTitle: 'Matrículas aprovadas',
            pageTitle: 'Matrículas Aprovadas',
            icon: 'enrollment',
            dataTable: {
              columns: {
                guardian: {
                  key: 'student.guardian.name',
                  dataIndex: 'student',
                  title: 'Nome do responsável',
                },
                student: {
                  key: 'student.name',
                  dataIndex: 'student',
                  title: 'Nome do aluno',
                },
                scholarGradeType: {
                  key: 'scholarGradeType',
                  title: 'Turma',
                },
                unitType: {
                  key: 'unitType',
                  title: 'Unidade',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                process: {
                  key: 'student.enrollmentType',
                  dataIndex: 'student',
                  title: 'Processo',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
          enrollmentDetail: {
            sidebarVisible: 'n',
            url: '/painel/matriculas/detalhes',
            // sidebarTitle: 'Matrículas aprovadas',
            pageTitle: 'Detalhe do aluno',
            icon: 'user',
            table: {
              guardian: {
                header: 'Dados do responsável',
                cpf: 'CPF',
                email: 'E-mail',
                name: 'Nome completo',
                phone: 'Telefone',
                rg: 'RG',
                city: 'Cidade',
                complement: 'Complemento',
                neighborhood: 'Bairro',
                number: 'Número',
                state: 'Estado',
                street: 'Endereço',
                zipCode: 'CEP',
              },
              student: {
                header: 'Dados do aluno',
                name: 'Nome',
                birthday: 'Data de nascimento',
                cpf: 'CPF',
                rg: 'RG',
                unitType: 'Unidade',
                scholarGradeType: 'Turma',
                city: 'Cidade',
                complement: 'Complemento',
                neighborhood: 'Bairro',
                number: 'Número',
                state: 'Estado',
                street: 'Endereço',
                zipCode: 'CEP',
                sameAddressAsGuardian: 'Mesmo endereço do responsável',
              },
              payment: {
                header: 'Pagamentos',
                orderNumber: 'Número',
                orderStatus: 'Status',
                totalPrice: 'Valor total',
                installmentsNum: 'Parcela',
                interestAmount: 'Juros',
                paidAt: 'Pago em',
                refunded: 'Estorno',
                refundedAt: 'Data do estorno',
              },
              pendency: {
                header: 'Pendência',
              },
              document: {
                header: 'Documentos',
              },
            },
          },
        },
      },
      financial: {
        url: '/painel/financeiro',
        sidebarVisible: 'n',
        sidebarTitle: 'Financeiro',
        icon: 'financial',
        items: {
          discount: {
            sidebarVisible: 'n',
            url: '/painel/financeiro/descontos',
            sidebarTitle: 'Descontos',
            pageTitle: 'Gerenciar descontos',
            icon: 'financial',
            addNewButtonText: 'Adicionar desconto',
            pageTitleAdd: 'Adicionar desconto',
            pageTitleEdit: 'Editar desconto',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um desconto',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um desconto',
            details: {
              form: {
                name: {
                  label: 'Nome',
                },
                maxValue: {
                  label: 'Valor máximo',
                },
                percentage: {
                  label: 'Porcentagem',
                },
                code: {
                  label: 'Código',
                },
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                maxValue: {
                  key: 'maxValue',
                  title: 'Valor máximo',
                },
                percentage: {
                  key: 'percentage',
                  title: 'Porcentagem',
                },
                code: {
                  key: 'code',
                  title: 'Código',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
          payment: {
            url: '/painel/financeiro/pagamentos',
            sidebarTitle: 'Pagamentos',
            pageTitle: 'Gerenciar pagamentos',
            icon: 'financial',
            addNewButtonText: 'Adicionar pagamento',
            pageTitleAdd: 'Adicionar pagamento',
            pageTitleEdit: 'Editar pagamento',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um pagamento',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um pagamento',
            details: {
              form: {
                enrollmentValue: {
                  label: 'Valor',
                },
                maxDate: {
                  label: 'Data limite',
                },
                paymentType: {
                  label: 'Tipo de pagamento',
                },
                interest: {
                  label: 'Juros',
                },
                installments: {
                  label: 'Parcelas',
                },
              },
            },
            dataTable: {
              columns: {
                enrollmentValue: {
                  key: 'enrollmentValue',
                  title: 'Valor',
                },
                maxDate: {
                  key: 'maxDate',
                  title: 'Data limite',
                },
                paymentType: {
                  key: 'paymentType',
                  title: 'Tipo de pagamento',
                },
                installments: {
                  key: 'installments.isActive',
                  dataIndex: 'installments',
                  title: 'Parcelado em:',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
        },
      },
      pushNotification: {
        url: '/painel/notificacoes/',
        sidebarVisible: 'n',
        sidebarTitle: 'Notificação',
        icon: 'financial',
        sidebarItemList: 'Lista',
        pageTitle: 'Notificação',
        addNewButtonText: 'Adicionar nova',
        items: {
          pushNotification: {
            url: '/painel/notificacoes/push',
            sidebarTitle: 'Lista de push',
            pageTitle: 'Notificação',
            icon: 'financial',
            addNewButtonText: 'Nova notificação',
            pageTitleAdd: 'Nova notificação',
            pageTitleEdit: 'Visualizar notificação',
            details: {
              form: {
                blockTitle: 'Detalhes da notificação',
                types: {
                  label: 'Desejo enviar para',
                },
                title: {
                  label: 'Título da notificação',
                },
                body: {
                  label: 'Descrição',
                },
                scheduleDate: {
                  label: 'Data de envio',
                },
                isSchedule: {
                  label: 'Agendar?',
                },
                hasImage: {
                  label: 'Incluir imagem no corpo da notificação?',
                },
                sendToAll: {
                  label: 'Enviar para todos',
                },
                image: {
                  dragger: {
                    title: 'Imagem do corpo da notificação',
                    tip: 'Clique aqui ou arraste e solte uma imagem dentro da área tracejada',
                  },
                },
                datatable: {
                  title: 'Usuários',
                },
              },
            },
            dataTable: {
              columns: {
                title: {
                  key: 'title',
                  title: 'Título',
                },
                scheduleDate: {
                  key: 'scheduleDate',
                  title: 'Data do envio',
                },
                userCount: {
                  key: 'userCount',
                  title: 'Qtd de destinatários',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data da criação',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
        },
      },
    },
  },

  enum: {
    profileType: {
      admin: 'Admin',
      driver: 'Motorista',
      assembler: 'Montador',
    },
    enrollmentPendencyType: {
      noVacancy: 'Não há vagas',
      payment: 'Pagamento',
    },
    enrollmentPaymentType: {
      splip: 'Boleto',
      card: 'Cartão',
      all: 'Cartão e boleto',
    },
    scholarGradeType: {
      sixth: '6º ano - Ens. Fun.',
      seventh: '7º ano - Ens. Fun.',
      eighth: '8º ano - Ens. Fun.',
      ninth: '9º ano - Ens. Fun.',
      tenth: '1º ano - Ens. Médio',
      eleventh: '2º ano - Ens. Médio',
    },
    unitType: {
      penha: 'Penha',
      vilaDasMerces: 'Vila das Merces',
      vilaMascote: 'Vila Mascote',
      jardinMarajoara: 'Jardim Marajoara',
    },
    orderStatus: {
      pending: 'Pendente',
      paid: 'Pago',
      approved: 'Aprovado',
      canceled: 'Cancelado',
    },
  },
};
