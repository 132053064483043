export const EnrollmentStatusType = {
  VACANCY_ANALYSIS: 1,
  PENDING_PAYMENT: 2,
  PENDING_CONTRACT: 3,
  PENDING_DOCUMENTATION: 4,
  PAYMENT_ERROR: 5,
  APPROVED: 6,
  DECLINED: 7,
  FINISHED: 8,
  PROCESSING_PAYMENT: 9,
  FINANCIAL_ANALYSIS: 10,
};

export const EnrollmentStatusTypeArray = [
  { id: EnrollmentStatusType.VACANCY_ANALYSIS, name: 'Análise da vaga', color: '#fcba03', order: 1 },
  { id: EnrollmentStatusType.PENDING_PAYMENT, name: 'Pagamento', color: '#fc3503', order: 2 },
  { id: EnrollmentStatusType.PENDING_CONTRACT, name: 'Contrato', color: '#fc9a63', order: 5 },
  { id: EnrollmentStatusType.PENDING_DOCUMENTATION, name: 'Documentações', color: '#3c0af3', order: 6 },
  { id: EnrollmentStatusType.PAYMENT_ERROR, name: 'Erro no pagamento', color: '#cc0a03', order: 7 },
  { id: EnrollmentStatusType.APPROVED, name: 'Matrículas aprovadas', color: '#0c9903', order: 8 },
  { id: EnrollmentStatusType.DECLINED, name: 'Matrículas rejeitadas', color: '#555555', order: 9, hidden: true },
  { id: EnrollmentStatusType.FINISHED, name: 'Finalizado', color: '#0ce903', order: 10, hidden: true },
  { id: EnrollmentStatusType.PROCESSING_PAYMENT, name: 'Processando pagamento', color: '#ac9503', order: 3 },
  { id: EnrollmentStatusType.FINANCIAL_ANALYSIS, name: 'Análise financeira', color: '#c4aa00', order: 4 },
];

export const GetEnrollmentStatusName = (value) => {
  const enrollmentStatus = EnrollmentStatusTypeArray.find((o) => o.id === value);
  if (enrollmentStatus) {
    return enrollmentStatus.name;
  }
  return value;
};

export const GetEnrollmentStatusColor = (value) => {
  const enrollmentStatus = EnrollmentStatusTypeArray.find((o) => o.id === value);
  if (enrollmentStatus) {
    return enrollmentStatus.color;
  }
  return '#D10072';
};
