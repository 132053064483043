import Immutable from 'seamless-immutable';

import * as constAction from '../actions/pushNotification';

const initialState = Immutable({
  dataPaginated: null,
  dataDetails: null,
});

export default function pushNotification(
  state = initialState,
  action,
) {
  switch (action.type) {
    case constAction.ACTION_SAVE_PUSH_NOTIFICATIONS_PAGINATED:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
    case constAction.ACTION_SAVE_PUSH_NOTIFICATION_DETAILS:
      state = {
        ...state,
        dataDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.pushNotification.dataPaginated;
}

export function getDataDetails(state) {
  return state.pushNotification.dataDetails;
}
