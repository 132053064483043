export const ENDPOINTS = {
  users: '/v1/user',
  student: '/v1/student',
  discounts: '/v1/discounts',
  enrollment: '/v1/enrollment',
  enrollmentKanban: '/v1/enrollment/kanban',
  enrollmentDocument: '/v1/enrollment-document',
  pushNotification: '/v1/notification',
  pushNotificationSend: '/v1/notification/send',
  payment: '/v1/payment-config',
};
