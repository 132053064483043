import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';

class DiscountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: null,
        maxValue: null,
        percentage: null,
        code: null,
      },
    };
  }

  componentDidMount() {
    const { data, id } = this.props;
    if (data && id !== 'add') {
      this.setState({
        form: {
          name: data && data.name,
          maxValue: data && data.maxValue,
          percentage: data && data.percentage,
          code: data && data.code,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;

    const scheman = yup.object().shape({
      maxValue: yup.string()
        .nullable({ isNullable: false })
        .required(I18n.t('shared.messages.errors.forms.invalid_maxValue')),
      name: yup.string()
        .min(5, I18n.t('shared.messages.errors.forms.invalid_name'))
        .nullable({ isNullable: false })
        .required(I18n.t('shared.messages.errors.forms.invalid_name')),
    });

    scheman
      .validate(form)
      .then(() => {
        const { data, submitFunction } = this.props;

        submitFunction(data ? data.id : null, form);
      }).catch((error) => {
        message.error(error.message);
      });
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const {
      loading,
    } = this.props;
    const { form } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form
              name="discountForm"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.financial.items.discount.details.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.financial.items.discount.details.form.percentage.label')}
                    value={form && form.percentage}
                    onChange={(val) => this.fieldChange('percentage', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.financial.items.discount.details.form.maxValue.label')}
                    value={form && form.maxValue}
                    onChange={(val) => this.fieldChange('maxValue', val)}
                  />
                </Col>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.financial.items.discount.details.form.code.label')}
                    value={form && form.code}
                    onChange={(val) => this.fieldChange('code', val)}
                  />
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.cancel')}
                    href={I18n.t('routes.panel.financial.items.discount.url')}
                  />
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.save')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(
  mapStateToProps,
  null,
)(
  DiscountForm,
);
