export const DocumentType = {
  LEGAL_GUARDIANS_CPF: 1,
  FINANCIAL_GUARDIAN_CPF: 2,
  LEGAL_GUARDIANS_RG: 3,
  FINANCIAL_GUARDIAN_RG: 4,
  STUDENT_BIRTH_CERTIFICATE_OR_RG: 5,
  GUARDIANSHIP_PROOF: 6,
  STUDENT_PROOF_OF_RESIDENT: 7,
  GUARDIAN_PROOF_OF_RESIDENT: 8,
  SCHOLARSHIP_CERTIFICATE_OR_SCHOOL_TRANSFERENCE: 9,
  SCHOLARSHIP_HISTORY: 10,
  PREVIOUS_SCHOOL_DEBT_NONEXISTENCE: 11,
  ENROLLMENT_CONTRACT: 12,
};

export const DocumentTypeArray = [
  { id: DocumentType.LEGAL_GUARDIANS_CPF, name: 'CPF do responsável legal' },
  { id: DocumentType.FINANCIAL_GUARDIAN_CPF, name: 'CPF do responsável financeiro' },
  { id: DocumentType.LEGAL_GUARDIANS_RG, name: 'RG do responsável legal' },
  { id: DocumentType.FINANCIAL_GUARDIAN_RG, name: 'RG do responsável financeiro' },
  { id: DocumentType.STUDENT_BIRTH_CERTIFICATE_OR_RG, name: 'RG ou Cert. nascimento do estudante' },
  { id: DocumentType.GUARDIANSHIP_PROOF, name: 'Prova de guarda' },
  { id: DocumentType.STUDENT_PROOF_OF_RESIDENT, name: 'Compr. residência do estudante' },
  { id: DocumentType.GUARDIAN_PROOF_OF_RESIDENT, name: 'Compr. residência do responsável' },
  { id: DocumentType.SCHOLARSHIP_CERTIFICATE_OR_SCHOOL_TRANSFERENCE, name: 'Certificado de transferência' },
  { id: DocumentType.SCHOLARSHIP_HISTORY, name: 'Histórico escolar' },
  { id: DocumentType.PREVIOUS_SCHOOL_DEBT_NONEXISTENCE, name: 'Compr. de não devedor' },
  { id: DocumentType.ENROLLMENT_CONTRACT, name: 'Contrato de matrícula' },
];

export const GetDocumentTypeName = (value) => {
  const enrollmentType = DocumentTypeArray.find((o) => o.id === value);
  if (enrollmentType) {
    return enrollmentType.name;
  }
  return value;
};
