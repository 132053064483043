import React from 'react';
import {
  Row,
  Col,
  Layout,
  Card,
  message, Divider,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { Link, navigate } from '@reach/router';
import { connect } from 'react-redux';

import AdvancedInput from '../../components/shared/AdvancedInput';
import AdvancedButton from '../../components/shared/AdvancedButton';
import { AuthActions } from '../../app/redux/actions';
import { LoadingSelectors } from '../../app/redux/reducers';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '', // raphamirra@gmail.com
    };
  }

  sendRecoveryEmail(e) {
    e.preventDefault();

    const { sendRecoveryEmail } = this.props;
    const { email } = this.state;

    if (!email) {
      return message.error(I18n.t('routes.forgotPassword.errors.fields'));
    }

    sendRecoveryEmail({ email }, () => {
      navigate(`${I18n.t('routes.login.url')}`);
      message.success(I18n.t('shared.messages.success.emailSent'));
    });
  }

  render() {
    const { Content } = Layout;
    const {
      email,
    } = this.state;
    const {
      loading,
    } = this.props;
    return (
      <Content className="forgot-password__container">
        <Row
          type="flex"
          justify="center"
        >
          <div className="
          forgot-password__container__background-image
          forgot-password__container__background-image--top-left"
          />
          <div className="
          forgot-password__container__background-image
          forgot-password__container__background-image--top-right"
          />
          <div className="
          forgot-password__container__background-image
          forgot-password__container__background-image--bottom-left"
          />

          <Col span={6}>
            <Row className="forgot-password__logo">
              <Col span={24}>
                <img
                  alt="forgot-password logo"
                  className="forgot-password__logo__img"
                  src="/assets/img/logo_color.svg"
                />
              </Col>
            </Row>
            <Card>
              <form onSubmit={(e) => this.sendRecoveryEmail(e)}>

                <Row>
                  <Col span={24}>
                    <p className="forgot-password__sub-header text-center">
                      {I18n.t('routes.forgotPassword.content.subHeader')}
                    </p>

                    <Divider />
                  </Col>
                </Row>

                <Row className="forgot-password__input">
                  <Col span={24}>
                    <AdvancedInput
                      label={I18n.t('routes.forgotPassword.content.email')}
                      placeholder={I18n.t('routes.forgotPassword.content.emailPlaceholder')}
                      type="email"
                      value={email}
                      onChange={(value) => this.setState({ email: value })}
                      disabled={loading && loading > 0}
                    />
                  </Col>
                </Row>
                <Row
                  className="forgot-password__input forgot-password__input__button"
                >
                  <Col
                    span={12}
                  >
                    <Link
                      className="forgot-password__input__button__back"
                      to={I18n.t('routes.login.url')}
                    >
                      {I18n.t('routes.forgotPassword.content.backToLogin')}
                    </Link>
                  </Col>

                  <Col
                    className="text-right"
                    span={12}
                  >
                    <AdvancedButton
                      text={I18n.t('routes.forgotPassword.content.continueButton')}
                      loading={loading && loading > 0}
                      htmlType="submit"
                    />
                  </Col>
                </Row>
              </form>
            </Card>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  sendRecoveryEmail: (data, callback) => dispatch(AuthActions.sendRecoveryEmail(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPassword);
