import React from 'react';
import {
  LockOutlined, BarcodeOutlined, CreditCardOutlined, UserOutlined,
} from '@ant-design/icons';
import { ReactComponent as EnrollmentIcon } from '../assets/img/enrollment_icon.svg';
import { ReactComponent as FinancialIcon } from '../assets/img/financial_icon.svg';
import { ReactComponent as RegistrationIcon } from '../assets/img/registration_icon.svg';

export const IconsList = {
  ENROLLMENT: 'enrollment',
  REGISTRATION: 'registration',
  FINANCIAL: 'financial',
  LOCK: 'lock',
  BARCODE: 'barcode',
  CREDIT_CARD: 'creditCard',
  USER: 'user',
};

export const IconsListArray = [
  { name: IconsList.ENROLLMENT, icon: <EnrollmentIcon /> },
  { name: IconsList.REGISTRATION, icon: <RegistrationIcon /> },
  { name: IconsList.FINANCIAL, icon: <FinancialIcon /> },
  { name: IconsList.LOCK, icon: <LockOutlined /> },
  { name: IconsList.BARCODE, icon: <BarcodeOutlined /> },
  { name: IconsList.CREDIT_CARD, icon: <CreditCardOutlined /> },
  { name: IconsList.USER, icon: <UserOutlined /> },
];

export const GetIcon = (iconName, props = { fill: '#fff' }) => {
  const iconItem = IconsListArray.find((o) => o.name === iconName);
  if (iconItem) {
    return React.cloneElement(iconItem.icon, props);
  }
  return <RegistrationIcon />;
};
