import React from 'react';
import {
  Button,
} from 'antd';
import { Link } from '@reach/router';

const AdvancedButton = (
  {
    text,
    onClick,
    type,
    clickValue,
    disabled,
    icon,
    shape,
    loading,
    htmlType,
    href,
    target,
    iconLeft,
    long,
    className,
    style,
  },
) => (
  <Button
    type={type || 'primary'}
    onClick={() => (onClick ? onClick(clickValue || null) : undefined)}
    shape={shape || undefined}
    disabled={disabled || false}
    loading={loading || false}
    htmlType={htmlType || 'button'}
    target={target || '_self'}
    style={long ? { width: '100%', ...style } : { ...style }}
    className={['advanced-button', className].join(' ')}
  >
    {href ? (
      <Link to={href}>
        {iconLeft && icon} {text} {!iconLeft && icon && icon}
      </Link>
    ) : (
      <span>{iconLeft && icon} {text} {!iconLeft && icon && icon}</span>
    )}
  </Button>
);

export default AdvancedButton;
